import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { get } from "helpers/api_helper"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import { Spinner } from 'reactstrap';

import servicesIcon1 from "../../assets/images/apartment.png"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import domainIcon from "../../assets/images/domain.png"

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart"
import RadialChart from "../AllCharts/apex/apexdonut"
import Apexdonut from "../AllCharts/apex/apexdonut1"
import SparkLine from "../AllCharts/sparkline/sparkline"
import SparkLine1 from "../AllCharts/sparkline/sparkline1"
import Salesdonut from "../AllCharts/apex/salesdonut"

import "chartist/dist/scss/chartist.scss"
import { isAdmin } from "helpers/validateToken"

//i18n
import { withTranslation } from "react-i18next"

const Dashboard = () => {
  const [apartmentcount, setApartmentcount] = useState(0)
  const [residenceCount, setResidenceCount] = useState(0)
  const [bookingCount, setBookingCount] = useState(0)
  const [userCount, setUserCount] = useState(0)
  const [userDomain, setUserDomain] = useState("")
  const [loading, setLoading] = useState(true)

  const [tarnsactionData, setTrancsactionData] = useState()

  const userImages = [user2, user3, user4, user5, user6]
  const fetchCounts = async () => {
    try {
      const token = authHeader() // Fetch the authorization token
      const user = JSON.parse(localStorage.getItem("authUser")) // Retrieve the logged-in user's information
      const userId = user.user?.id

      const config = {
        headers: {
          Authorization: token,
        },
      }

      // Initialize params
      const params = new URLSearchParams()
      if (!isAdmin) {
        params.append("owner_id", userId)
      }

      // Define URLs based on user role
      const apartmentUrl = !isAdmin
        ? `/apartmentswithsearch?${params.toString()}`
        : `apartmentswithsearch`
      const residenceUrl = !isAdmin
        ? `/allresidenceswithfilter?${params.toString()}`
        : `allresidenceswithfilter`
      const bookingsUrl = !isAdmin
        ? `/bookingswithfilters?${params.toString()}`
        : `bookingswithfilters`
      const userSUrl = isAdmin ? "/users" : ""
      // Make all requests concurrently
      const requests = [
        get(apartmentUrl, config),
        get(residenceUrl, config),
        get(bookingsUrl, config),
      ]

      // Conditionally add the user API call if isAdmin is true
      if (isAdmin) {
        requests.push(get(userSUrl, config))
      }

      const [apartmentRes, residenceRes, bookingsRes, userRes] =
        await Promise.all(requests)
      setTrancsactionData(bookingsRes?.data)
      setLoading(false)
      // Update state based on responses
      setUserCount(userRes?.pagination?.totalItems)
      setApartmentcount(apartmentRes?.pagination?.totalItems || 0)
      setResidenceCount(residenceRes?.pagination?.totalItems || 0)
      setBookingCount(bookingsRes?.total || 0)
    } catch (error) {
      console.log("Error:", error)
    }
  }

  useEffect(() => {
   

    const userData = JSON.parse(localStorage.getItem("authUser"))

    setUserDomain(userData?.user?.domain)

    fetchCounts()
  }, [apartmentcount, residenceCount, bookingCount,isAdmin])

  document.title = "Dashboard | SHS  Admin"
  return (
    <React.Fragment>
      <div className="page-content">
     

        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to SHS Dashboard
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img
                        src={servicesIcon1}
                        alt=""
                        style={{
                          marginRight: "10px",
                          width: "20px",
                          height: "auto",
                          filter: "invert(1)",
                          opacity: "0.65",
                        }}
                      />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Apartments
                    </h5>
                 { !loading ?  <h4 className="fw-medium font-size-24">
                      { apartmentcount}  
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>: <Spinner color="white" />}
                    {/* this is tag commented here */}
                    {/* <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <i className="ti-home"></i>
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Residences
                    </h5>
                    { !loading ?  <h4 className="fw-medium font-size-24">
                      { residenceCount}  
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>: <Spinner color="white" />}
                    {/* this is tag commented here */}

                    {/* <div className="mini-stat-label bg-danger">
                      <p className="mb-0">- 28%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <i className="ti-calendar"></i>
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Bookings
                    </h5>
                    { !loading ?  <h4 className="fw-medium font-size-24">
                      { bookingCount}  
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>: <Spinner color="white" />}
                    {/* this is tag commented here */}

                    {/* <div className="mini-stat-label bg-info">
                      <p className="mb-0">00%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      {isAdmin ? (
                        <i className="ti-user"></i>
                      ) : (
                        <img
                          alt="domain-Icons"
                          src={domainIcon}
                          style={{
                            width: "25px",
                            height: "auto",
                            filter: "invert(1)",
                          }}
                        />
                      )}
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      {isAdmin ? "USERS" : "DOMAIN"}
                    </h5>
                    {isAdmin ? (
                   
                         !loading ?  <h4 className="fw-medium font-size-24">
                        {userCount}{" "}
                        <i className="mdi mdi-arrow-up text-success ms-2"></i>
                      </h4>: <Spinner color="white" />
                    ) : (
                      <a
                        href={userDomain}
                        target="_blank" // Open in a new tab
                        rel="noopener noreferrer" // Security best practice
                        style={{ color: "white" }}
                      >
                        {userDomain}
                      </a>
                    )}
                    {/* this is tag commented here */}

                    {/* <div className="mini-stat-label bg-warning">
                      <p className="mb-0">+ 84%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">
                      {/* Since last month */}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={9}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Monthly Earning</h4>
                  <Row>
                    <Col lg={7}>
                      <div>
                        <LineAreaChart />
                      </div>
                    </Col>
                    <Col lg={5}>
                      <Row>
                        <Col md={6}>
                          <div className="text-center">
                            <p className="text-muted mb-4">This month</p>
                            <h3>$34,252</h3>
                            <p className="text-muted mb-4">
                              It will be as simple as in fact it will be
                              occidental.
                            </p>
                            <RadialChart />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="text-center">
                            <p className="text-muted mb-4">Last month</p>
                            <h3>$36,253</h3>
                            <p className="text-muted mb-4">
                              It will be as simple as in fact it will be
                              occidental.
                            </p>
                            <Apexdonut />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl={3}>
              <Card>
                <CardBody>
                  <div>
                    <h4 className="card-title mb-4">Sales Analytics</h4>
                  </div>
                  <div className="wid-peity mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Online</p>
                          <h5 className="mb-4">1,542</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wid-peity mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Offline</p>
                          <h5 className="mb-4">6,451</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine1 />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Marketing</p>
                          <h5>84,574</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={3}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Sales Report</h4>
                  <div className="cleafix">
                    <p className="float-start">
                      <i className="mdi mdi-calendar me-1 text-primary"></i> Jan
                      01 - Jan 31
                    </p>
                    <h5 className="font-18 text-end">$4230</h5>
                  </div>
                  <div id="ct-donut" className="ct-chart wid pt-4">
                    <Salesdonut />
                  </div>
                  <div className="mt-4">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td>
                            <span className="badge bg-primary">Desk</span>
                          </td>
                          <td>Desktop</td>
                          <td className="text-end">54.5%</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge bg-success">Mob</span>
                          </td>
                          <td>Mobile</td>
                          <td className="text-end">28.0%</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge bg-warning">Tab</span>
                          </td>
                          <td>Tablets</td>
                          <td className="text-end">17.5%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Activity</h4>
                  <ol className="activity-feed">
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 22</span>
                        <span className="activity-text">
                          Responded to need “Volunteer Activities”
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 20</span>
                        <span className="activity-text">
                          At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui deleniti atque...
                          <Link to="#" className="text-success">
                            Read more
                          </Link>
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 19</span>
                        <span className="activity-text">
                          Joined the group “Boardsmanship Forum”
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 17</span>
                        <span className="activity-text">
                          Responded to need “In-Kind Opportunity”
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 16</span>
                        <span className="activity-text">
                          Sed ut perspiciatis unde omnis iste natus error sit
                          rem.
                        </span>
                      </div>
                    </li>
                  </ol>
                  <div className="text-center">
                    <Link to="#" className="btn btn-primary">
                      Load More
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={5}>
              <Row>
                <Col md={6}>
                  <Card className="text-center">
                    <CardBody>
                      <div className="py-4">
                        <i className="ion ion-ios-checkmark-circle-outline display-4 text-success"></i>

                        <h5 className="text-primary mt-4">Order Successful</h5>
                        <p className="text-muted">
                          Thanks you so much for your order.
                        </p>
                        <div className="mt-4">
                          <Link to="#" className="btn btn-primary btn-sm">
                            Chack Status
                          </Link>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="bg-primary">
                    <CardBody>
                      <div className="text-center text-white py-4">
                        <h5 className="mt-0 mb-4 text-white-50 font-size-16">
                          Top Product Sale
                        </h5>
                        <h1>1452</h1>
                        <p className="font-size-14 pt-1">Computer</p>
                        <p className="text-white-50 mb-0">
                          At solmen va esser necessi far uniform myth...{" "}
                          <Link to="#" className="text-white">
                            View more
                          </Link>
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Client Reviews</h4>
                      <p className="text-muted mb-3 pb-4">
                        &quot Everyone realizes why a new common language would
                        be desirable one could refuse to pay expensive
                        translators it would be necessary. &quot
                      </p>
                      <div className="float-end mt-2">
                        <Link to="#" className="text-primary">
                          <i className="mdi mdi-arrow-right h5"></i>
                        </Link>
                      </div>
                      <h6 className="mb-0">
                        {" "}
                        <img
                          src={user3}
                          alt=""
                          className="avatar-sm rounded-circle me-2"
                        />{" "}
                        James Athey
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Latest Transaction</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">(#) Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Date</th>
                          <th scope="col">Amount</th>
                          <th scope="col" colSpan="2">
                            Status
                          </th>
                        </tr>
                      </thead>
                      {tarnsactionData?.length > 0 ? (
                        <tbody>
                          {tarnsactionData?.map((item, index) => {
                            const fullName =
                              item.detail?.billing.first_name +
                              " " +
                              item.detail?.billing.last_name
                            const date = new Date(item.created_at)
                            const formattedDate = `${date.getDate()}/${
                              date.getMonth() + 1
                            }/${date.getFullYear()}`
                            const amount = item.detail?.total || "0" // Assuming amount is in detail object
                            const statusBadge =
                              item.status === "completed" ? (
                                <span className="badge bg-success">
                                  Delivered
                                </span>
                              ) : item.status === null ? (
                                <span className="badge bg-warning">
                                  Pending
                                </span>
                              ) : (
                                <span className="badge bg-danger">Cancel</span>
                              )

                            return (
                              <tr key={item.id}>
                                <th scope="row">#{item.id}</th>
                                <td>
                                  <div>
                                    <img
                                      src={
                                        userImages[index % userImages.length]
                                      }
                                      alt=""
                                      className="avatar-xs rounded-circle me-2"
                                    />
                                    {fullName}{" "}
                                    {/* Replace with actual name if available */}
                                  </div>
                                </td>
                                <td>{formattedDate}</td>
                                <td>
                                  $ {""}
                                  {amount}
                                </td>
                                <td>{statusBadge}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      ) : (
                        <p>No Transaction Found</p>
                      )}
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
