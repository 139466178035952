import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types"; // For prop-type validation
import VerticalLayout from "components/VerticalLayout";

const Authmiddleware = ({ children }) => {
  const location = useLocation();

  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: location } }} />
    );
  }

  return (
    <React.Fragment>
      <VerticalLayout>{children}</VerticalLayout>
    </React.Fragment>
  );
};

// Prop validation
Authmiddleware.propTypes = {
  children: PropTypes.node.isRequired, // Ensure 'children' is valid React nodes
};

export default Authmiddleware;


























// import React from "react";
// import { Navigate } from "react-router-dom";
// import PropTypes from "prop-types"; // For prop-type validation
// import VerticalLayout from "components/VerticalLayout";

// const Authmiddleware = (props) => {
//   const { location, children } = props;

//   if (!localStorage.getItem("authUser")) {
//     return (
//       <Navigate to={{ pathname: "/login", state: { from: location } }} />
//     );
//   }

//   return (
//     <React.Fragment>
//       <VerticalLayout>{children}</VerticalLayout>
//     </React.Fragment>
//   );
// };

// // Prop validation
// Authmiddleware.propTypes = {
//   location: PropTypes.object.isRequired, // Ensure 'location' is an object
//   children: PropTypes.node.isRequired,   // Ensure 'children' is valid React nodes
// };

// export default Authmiddleware;




