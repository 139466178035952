import { TOGGLE_APARTMENT_EDIT } from './actionTypes'; // Import the action type

const initialState = {
  isEditing: false, // Initial state
};

const apartmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_APARTMENT_EDIT: // Use the imported action type
      return {
        ...state,
        isEditing: action.payload, // Update the state with the payload
      };
    default:
      return state;
  }
};

export default apartmentReducer;