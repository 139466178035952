// import jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode"

export function ValidateUser(navigate) {
  const obj = JSON.parse(localStorage.getItem("authUser"))

  if (!obj) {
    navigate("/logout")
  } else if (!validateToken(obj.token)) {
    navigate("/logout")
  }
}
function validateToken(accessToken) {
  var isExpired = false

  if (accessToken) {
    let token = jwtDecode(accessToken)
    let currentDate = new Date()

    //   console.log(token)
    //   JWT exp is in seconds
    if (token.exp * 1000 < currentDate.getTime()) {
      isExpired = false
    } else {
      isExpired = true
    }
  }

  return isExpired
}

export function hasPermission(permissions, conponent, permission_grp, isAdmin) {


  if (isAdmin === "Admin" || isAdmin === "SHS Team") {
    return true
  }


  let role = permissions[conponent]

  return permission_grp?.includes(role)
}

// for checking  the admin
const obj = JSON.parse(localStorage.getItem("permission"))
const userRole = obj?.role_name

// Check if the user is Admin or part of SHS TEAM
// const isAdmin = userRole === "Admin" || userRole === "SHS TEAM";
export const isAdmin =
  userRole?.toLowerCase() === "shs team".toLowerCase() ||
  userRole?.toLowerCase() === "admin".toLowerCase()

