import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, FormGroup, Label, Button, InputGroup } from "reactstrap"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css" // You can change the theme based on your requirement
import moment from "moment"

import {  useParams } from "react-router-dom"

const Slots = ({ values, onInputChange, onSubmit }) => {
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)


  const { id } = useParams()

  // Set default start date as September 1 and end date as May 31
  const currentYear = moment().year()

  // Set default start date to June 1 of the current year
  const defaultStartDate = moment(
    `1 June ${currentYear}`,
    "D MMMM YYYY"
  ).toDate()

  // Set default end date to May 31 of the next year
  const defaultEndDate = moment(
    `31 May ${currentYear + 1}`,
    "D MMMM YYYY"
  ).toDate()

  useEffect(() => {
    if (values.slot) {
      
      const dateStrings = values.slot.split(" ")

      // Check if dateStrings has the expected length of 8 parts
      if (dateStrings.length === 8) {
        // Example: "1 August 2025 5 May 2028"

        const startDate = moment(
          `${dateStrings[0]} ${dateStrings[1]} ${dateStrings[2]} ${dateStrings[3]}`,
          "D MMMM YYYY"
        ).toDate()

        const endDate = moment(
          `${dateStrings[4]} ${dateStrings[5]} ${dateStrings[6]}  ${dateStrings[7]}`,
          "D MMMM YYYY"
        ).toDate()

        // Set the parsed start and end dates
        setSelectedStartDate(startDate)
        setSelectedEndDate(endDate)
      }
    } else {
      // If no slot value, set default start and end dates
      setSelectedStartDate(defaultStartDate)
      setSelectedEndDate(defaultEndDate)
    }
  }, [values.slot])

 

  // Handle form submit
  const handleNext = () => {
    onSubmit(); 
  };
  

  // Handle start date change
  const handleStartDateChange = dates => {
    const startDate = dates[0] // Get the first date selected
    setSelectedStartDate(startDate) // Update state
    const endDate = selectedEndDate || defaultEndDate // Use existing end date or default
    const slotName = generateSlotName(startDate, endDate) // Generate slot name
    onInputChange({ slot: slotName }) // Trigger onInputChange
  }

  // Handle end date change
  const handleEndDateChange = dates => {
    const endDate = dates[0] // Get the first date selected
    setSelectedEndDate(endDate) // Update state
    const startDate = selectedStartDate || defaultStartDate // Use existing start date or default
    const slotName = generateSlotName(startDate, endDate) // Generate slot name
    onInputChange({ slot: slotName }) // Trigger onInputChange
  }

  // Generate slot name based on start and end dates
  const generateSlotName = (startDate, endDate) => {
    const formattedStartDate = moment(startDate).format(" D MMMM YYYY ")
    const formattedEndDate = moment(endDate).format("D MMMM YYYY")
    return `${formattedStartDate} - ${formattedEndDate}` // Combine the start and end date into a slot name
  }

  // Handle permissions


  return (
    <Col className="col-12">
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="startDate">
              Slot Start Date <span style={{ color: "red" }}>*</span>
            </Label>
            <InputGroup>
              <Flatpickr
                name="startDate"
                className="form-control d-block"
                placeholder="Select Start Date"
                options={{
                  dateFormat: "d M Y", // Format the selected dates
                }}
                value={
                  selectedStartDate ? [selectedStartDate] : [defaultStartDate]
                } // Flatpickr expects an array of dates
                onChange={handleStartDateChange} // Handle date change
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="endDate">
              Slot End Date <span style={{ color: "red" }}>*</span>
            </Label>
            <InputGroup>
              <Flatpickr
                name="endDate"
                className="form-control d-block"
                placeholder="Select End Date"
                options={{
                  dateFormat: "d M Y", // Format the selected dates
                }}
                value={selectedEndDate ? [selectedEndDate] : [defaultEndDate]} // Flatpickr expects an array of dates
                onChange={handleEndDateChange} // Handle date change
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}></Col>

          <Col md={6} className="d-flex justify-content-end">
            <Button color="primary" onClick={handleNext}>
              {id ? "Update" : "Submit"}
            </Button>
          </Col>
    
      </Row>
    </Col>
  )
}

// Define PropTypes
Slots.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Slots
