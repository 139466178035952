import PropTypes from "prop-types";
import React, { useEffect, useCallback, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link, useLocation } from "react-router-dom";
import apartmentIcon from "../../assets/images/apartment.png";
import roleIcan from "../../assets/images/role.png";
import { withTranslation } from "react-i18next";
import { hasPermission } from "helpers/validateToken";
import { get } from "helpers/api_helper";
import authHeader from "helpers/jwt-token-access/auth-token-header";

const SidebarContent = (props) => {
  const location = useLocation();
  const ref = useRef();
  const [permissions, setPermissions] = useState({});
  const [isAdmin, setIsAdmin] = useState("");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false); // State for the settings submenu

  const path = location.pathname;

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = location.pathname;
    const firstSegment = pathName.split("/").filter(Boolean)[0];

    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);
    let matchingMenuItem = null;

    if (firstSegment === "settings") {
      matchingMenuItem = Array.from(items).find(item =>
        item.pathname.startsWith("/settings")
      );
    } else {
      matchingMenuItem = Array.from(items).find(
        item => item.pathname === `/${firstSegment}`
      );
      setIsSettingsOpen(false); // Close settings when navigating away
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path, activateParentDropdown]);

  const getPermission = async (id) => {
    try {
      const token = authHeader();
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const res = await get(`/roles/${id}`, config);

      localStorage.setItem("permission", JSON.stringify(res?.data));
      setPermissions(JSON.parse(res?.data?.permission) || {});
      setIsAdmin(res.data?.role_name);
    } catch (error) {
      console.log("error-------", error);
    }
  };

  useEffect(() => {
    ref.current.recalculate();
    const getRole = localStorage.getItem("authUser");
    const role = JSON.parse(getRole);
    getPermission(role?.user?.role);
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const toggleSettings = () => {
    setIsSettingsOpen((prev) => !prev); // Toggle the settings submenu
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {hasPermission(
              permissions,
              "Dashboard",
              ["read", "Read-write"],
              isAdmin
            ) && (
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="ti-dashboard"></i>
                  <span className="badge rounded-pill bg-primary float-end">1</span>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
            )}

            {hasPermission(
              permissions,
              "Apartments",
              ["Read-only", "Read-write"],
              isAdmin
            ) && (
              <li>
                <Link to="/apartments" className="waves-effect">
                  <img
                    src={apartmentIcon}
                    alt="apartmentIcon"
                    height={20}
                    width={20}
                    style={{
                      marginRight: "19px",
                      width: "15px",
                      height: "auto",
                      filter: "invert(1)",
                      opacity: "0.65",
                    }}
                  />
                  <span>{props.t("Apartments")}</span>
                </Link>
              </li>
            )}

            {hasPermission(
              permissions,
              "Residence",
              ["Read-write", "Read-only"],
              isAdmin
            ) && (
              <li>
                <Link to="/residence" className="waves-effect">
                  <i className="ti-home"></i>
                  <span>{props.t("Residences")}</span>
                </Link>
              </li>
            )}

            {hasPermission(
              permissions,
              "Bookings",
              ["Read-only", "Read-write"],
              isAdmin
            ) && (
              <li>
                <Link to="/bookings" className="waves-effect">
                  <i className="ti-calendar"></i>
                  <span>{props.t("Bookings")}</span>
                </Link>
              </li>
            )}

            {hasPermission(
              permissions,
              "Settings",
              ["Read-only", "Read-write"],
              isAdmin
            ) && (
              <li>
                <Link to="#" className="has-arrow waves-effect" onClick={toggleSettings}>
                  <i className="ti-settings"></i>
                  <span>{props.t("Settings")}</span>
                </Link>
                {isSettingsOpen && (
                  <ul className="sub-menu" aria-expanded={isSettingsOpen}>
                    {hasPermission(
                      permissions,
                      "Users",
                      ["Read-write", "Read-only"],
                      isAdmin
                    ) && (
                      <li>
                        <Link to="/settings/users" className="waves-effect">
                          <i className="ti-user"></i>
                          <span>{props.t("Users")}</span>
                        </Link>
                      </li>
                    )}
                    {hasPermission(
                      permissions,
                      "Role",
                      ["Read-only", "Read-write"],
                      isAdmin
                    ) && (
                      <li>
                        <Link to="/settings/role" className="waves-effect">
                          <img
                            src={roleIcan}
                            alt="roleIcon"
                            height={20}
                            width={20}
                            style={{
                              marginRight: "19px",
                              width: "15px",
                              height: "auto",
                              filter: "invert(1)",
                              opacity: "0.65",
                            }}
                          />
                          <span>{props.t("Roles")}</span>
                        </Link>
                      </li>
                    )}
                    {hasPermission(
                      permissions,
                      "AddRoomtype",
                      ["Read-only", "Read-write"],
                      isAdmin
                    ) && (
                      <li>
                        <Link to="/settings/room-type" className="waves-effect">
                          <i className="ti-settings"></i>
                          <span>{props.t("Room Type")}</span>
                        </Link>
                      </li>
                    )}
                    {hasPermission(
                      permissions,
                      "AddSlot",
                      ["Read-only", "Read-write"],
                      isAdmin
                    ) && (
                      <li>
                        <Link to="/settings/slots" className="waves-effect">
                          <i className="ti-settings"></i>
                          <span>{props.t("Slots")}</span>
                        </Link>
                      </li>
                    )}
                    {hasPermission(
                      permissions,
                      "AddNeighbourhood",
                      ["Read-only", "Read-write"],
                      isAdmin
                    ) && (
                      <li>
                        <Link to="/settings/neighbourhood" className="waves-effect">
                          <i className="ti-settings"></i>
                          <span>{props.t("Neighbourhood")}</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
