import React from "react";
import activeImg from "../../assets/images/activeImg.png";
import inactiveImg from "../../assets/images/inactiveImg.png";
import PropTypes from "prop-types"

const ToggleButton = ({ isActive, onToggle }) => {
  return (
    <img
      src={isActive ? activeImg : inactiveImg}
      alt={isActive ? "Active" : "Inactive"}
      style={{ cursor: "pointer", height: "25px" }}
      onClick={onToggle} // Call the toggle function on click
    />
  );
};
ToggleButton.propTypes = {
  isActive: PropTypes.bool.isRequired, 

    onToggle: PropTypes.func.isRequired,
    
  }
export default ToggleButton;
