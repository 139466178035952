import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from "prop-types"
const VideoPlayer =({ url })=>{
    return(
        <ReactPlayer 
        url={url} 
        width="180px" // Set width
        height="180px" // Set height
        controls // Show video controls
    />   
    )

}
VideoPlayer.propTypes = {

    url: PropTypes.func.isRequired,
  }
export default VideoPlayer