import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap"
import Dropzone from "react-dropzone"
import PropTypes from "prop-types"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import { FaTimesCircle } from "react-icons/fa"
import Swal from "sweetalert2"
import Loader from "components/loader/Loader"
import { APIURL_MEDIA } from "helpers/api_helper"
import VideoPlayer from "components/videoplayer/VideoPlayer"
import {  useSelector } from 'react-redux';
const ResidenceMedia = ({ values, setActiveTab, onInputChange }) => {
  // State to hold selected media files and error messages
  const [selectedMedia, setSelectedMedia] = useState([])
  const [error, setError] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [newMediaName, setNewMediaName] = useState("")
  const [filesToUpload, setFilesToUpload] = useState([])
  const [uploadingMedia, setUploadingMedia] = useState([]);
  const [videUrl,setVideoUrl] = useState([])
  const [newUrl, setNewUrl] = useState("");
  const residenceEdit = useSelector((state) => state.residence.isEditing);

  // Effect to set selected media from props when values change
  useEffect(() => {
    if (values && values.media) {
      setSelectedMedia(values.media)
    }
    if(values.video){
      setVideoUrl(values.video)
    }
  }, [values])

  // Handle accepted media files, formatting them before upload
  const handleAcceptedMedia = media => {
    // Set the first media's name as default in the rename input
    const defaultName = media[0].name || "Unnamed"
    setNewMediaName(defaultName)
    const newUpdatedMedia = media.map(file => {
      return {
        file, // original File object
        path: newMediaName || file.name, // use the updated name or the original name
      }
    })
    if (media.length === 1) {
      setModalOpen(true)
      setFilesToUpload(media)
    } else {
      uploadMedia(newUpdatedMedia)
      setModalOpen(false)
    }
  }

  // Function to format byte size into human-readable format

  // Function to upload media files to the server
  const uploadMedia = async files => {
    const token = authHeader();

    const newUploadingMedia = files.map((_, index) => ({ index, loading: true }));
    setUploadingMedia(newUploadingMedia);

    const uploadedFiles = await Promise.all(
      files.map(async ({ file, path }, index) => {
        try {
          const Data = new FormData();
          Data.append("media", file, path);

          const options = {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: Data,
          };

       
          const response = await fetch(APIURL_MEDIA, options);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response}`);
          }

          const data = await response.json();
          const mediaUrl = data?.data[0]?.media_url;
          const mediaType = data?.data[0]?.media_type.split("/")[0];
          const media_name = data?.data[0]?.media_name;

          return {
            media_url: mediaUrl,
            mediaType: mediaType,
            mediaName: media_name,
          };
        } catch (error) {
          console.error("An error occurred", error);
          Swal.fire({
            title: "Error!",
            text: error.message || "An error occurred while uploading media!",
            icon: "error",
          });
          return null;
        } finally {
          setUploadingMedia(prev =>
            prev.map(item =>
              item.index === index ? { ...item, loading: false } : item
            )
          );
        }
      })
    );

    const successfulUploads = uploadedFiles.filter(file => file !== null);
    const updatedMedia = [...selectedMedia, ...successfulUploads];
    setSelectedMedia(updatedMedia);

    onInputChange({ target: { name: "media", value: updatedMedia } });
  };

  // Function to remove a media item by index
  const removeMedia = index => {
    const updatedMedia = selectedMedia?.filter((_, i) => i !== index) // Remove media at the given index
    setSelectedMedia(updatedMedia) // Update state

    // Update form data
    onInputChange({ target: { name: "media", value: updatedMedia } })
  }

  const handleUploadFiles = () => {
    const newUpdatedNames = filesToUpload.map(file => {
      return {
        file, // original File object
        path: newMediaName || file.name, // use the updated name or the original name
      }
    })

    uploadMedia(newUpdatedNames)
    setFilesToUpload([])
    setModalOpen(false)
    setNewMediaName("")
  }

  const handleCancelUpload = () => {
    const newUpdatedNames = filesToUpload.map(file => {
      return {
        file, // original File object
        path: newMediaName || file.name, // use the updated name or the original name
      }
    })
    uploadMedia(newUpdatedNames)

    setFilesToUpload([])
    setModalOpen(false)
    setNewMediaName("")
  }

  // Handle file drop event from Dropzone
  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setError("Only image files are allowed.") // Set error message for invalid files
      return
    }
    setError("") // Clear error message
    handleAcceptedMedia(acceptedFiles) // Process accepted files
  }

  // Handle the start of a drag event
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("dragIndex", index) // Store the index of the dragged item
    e.currentTarget.classList.add("dragging") // Add class for styling
  }

  // Handle the drop event to swap media items
  const handleDropMedia = (e, dropIndex) => {
    e.preventDefault()
    const dragIndex = e.dataTransfer.getData("dragIndex") // Get the index of the dragged item

    // If dragging the same item, do nothing
    if (dragIndex === dropIndex.toString()) return

    const updatedMedia = [...selectedMedia]

    // Swap the dragged item with the item at dropIndex
    const draggedItem = updatedMedia[dragIndex] // Get the dragged item
    updatedMedia[dragIndex] = updatedMedia[dropIndex] // Replace dragged item with the drop target
    updatedMedia[dropIndex] = draggedItem // Set dragged item to the drop target's previous position

    // Update order IDs based on the new arrangement
    updatedMedia.forEach((item, index) => {
      item.orderId = index + 1 // Set new order IDs based on position
    })

    setSelectedMedia(updatedMedia) // Update state
    onInputChange({ target: { name: "media", value: updatedMedia } }) // Update form data
    e.currentTarget.classList.remove("dragging") // Remove class after drop
  }

  // Handle drag over event to allow dropping
  const handleDragOver = e => {
    e.preventDefault() // Prevent default behavior to allow drop
  }

  // Handle the end of a drag event
  const handleDragEnd = e => {
    e.currentTarget.classList.remove("dragging") // Remove dragging class
  }

  const  addedVideo =()=>{
 
    const combinedUrls = [...videUrl, newUrl]
   
    setVideoUrl(combinedUrls); 
    onInputChange({ target: { name: "video", value: combinedUrls} })
    setNewUrl("");
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Video media added successfully!",
      showConfirmButton: false,
      timer: 3000,
    })
  
  }
  const removeVideo = (index) => {
    // Remove the video URL at the specified index
    const updatedUrls = videUrl.filter((_, i) => i !== index);
    setVideoUrl(updatedUrls); // Update state with the new array
    onInputChange({ target: { name: "video", value: updatedUrls} })
  
  };

  return (
    <React.Fragment>
      <Container fluid={true}>
      <div className="mt-3">
          <Input
            type="text"
            placeholder="Enter video URL"
            value={newUrl}
            onChange={(e) => setNewUrl(e.target.value)}
            readOnly={!residenceEdit}

          />
          <Button
            color="primary"
            onClick={addedVideo}
            className="mt-3 mb-4"
            disabled={!residenceEdit}

          >
            Add Video URL
          </Button>
        </div>
        <Row>
          <Col className="col-12">
            <div className="mb-5">
 {  residenceEdit &&           <Dropzone
                onDrop={handleDrop}
                accept={{ "image/*": [], }} // Accept only images and videos
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input name="img" {...getInputProps()} />
                      <div className="mb-3">
                        <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                      </div>
                      <h4>Drop media here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>}
              {error && <p className="text-danger">{error}</p>}{" "}
              {/* Display error message if any */}
              <div className="dropzone-previews mt-3" id="file-previews" style={{ marginBottom: "20px" }}>
  {selectedMedia?.map((f, i) => {
   
    const isLastUploading = uploadingMedia.some(item => item.loading) && i === selectedMedia?.length - 1;

    return (
      <Card
        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
        key={i + "-file"}
        draggable
        onDragStart={e => handleDragStart(e, i)}
        onDragOver={handleDragOver}
        onDrop={e => handleDropMedia(e, i)}
        onDragEnd={handleDragEnd}
        style={{ position: "relative" }}
      >
        {isLastUploading ? (
          <div style={{ height: "10rem", width: "11rem" }}>
            <Loader/> {/* Replace with your loader component */}
          </div>
        ) : (
          <>
         
                           <FaTimesCircle
  disabled={!residenceEdit}
  size={20}
  onClick={() => residenceEdit && removeMedia(i)}
  style={{
    position: "absolute",
    top: "-12px",
    right: "-12px",
    cursor: residenceEdit ? "pointer" : "not-allowed",
    opacity: residenceEdit ? 1 : 0.5, // Set opacity to indicate disabled state
    pointerEvents: residenceEdit ? "auto" : "none", // Prevent interaction when disabled
  }}
/>
            <Row className="align-items-center">
              <Col className="col-auto">
               
                  <img
                    data-dz-thumbnail=""
                    className="avatar-sm rounded bg-light"
                    style={{ height: "10rem", width: "11rem" }}
                    alt={f.name || "Uploaded media"}
                    src={f.media_url ? f.media_url : f.preview}
                  />
          
              </Col>
            </Row>
          </>
        )}
      </Card>
    );
  })}
</div>
<div
                className="dropzone-previews mt-3"
                id="file-previews"
                style={{ marginBottom: "20px" }}
              >
                {videUrl?.map((f, i) => {
              
    
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                      draggable
                      onDragStart={e => handleDragStart(e, i)}
                      onDragOver={handleDragOver}
                      onDrop={e => handleDropMedia(e, i)}
                      onDragEnd={handleDragEnd}
                      style={{ position: "relative" }}
                    >
                   
                        <>
                      
                                      <FaTimesCircle
  disabled={!residenceEdit}
  size={20}
  onClick={() => residenceEdit && removeVideo(i)}
  style={{
    position: "absolute",
    top: "-12px",
    right: "-12px",
    cursor: residenceEdit ? "pointer" : "not-allowed",
    opacity: residenceEdit ? 1 : 0.5, // Set opacity to indicate disabled state
    pointerEvents: residenceEdit ? "auto" : "none", // Prevent interaction when disabled
  }}
/>
                          <Row className="align-items-center">
                            <Col className="col-auto">
                          
                         
                          
                               <VideoPlayer url={f}/>
                             
                            </Col>
                          </Row>
                        </>
                   
                    </Card>
                  )
                })}
                
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Rename Media Modal */}
      <Modal isOpen={modalOpen}>
        <ModalHeader>
          Rename Media
          {/* <FaTimesCircle
            size={20}
            onClick={handleCancelUpload}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
          /> */}
        </ModalHeader>
        <ModalBody>
          <Input
            type="text"
            placeholder="Enter new media name"
            value={newMediaName}
            onChange={e => setNewMediaName(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCancelUpload}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleUploadFiles}>
            Upload
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col md={6}>
          <Button color="secondary" onClick={() => setActiveTab("2")}>
            Back
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={() => setActiveTab("4")}>
            Next
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

// Define PropTypes for component validation
ResidenceMedia.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

export default ResidenceMedia
