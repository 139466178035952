import { TOGGLE_RESIDENCE_EDIT } from "./ actionTypes";

const initialState = {
  isEditing: false,
};

const residenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_RESIDENCE_EDIT:
      return {
        ...state,
        isEditing: action.payload,
      };
    default:
      return state;
  }
};

export default residenceReducer;

