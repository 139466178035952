import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Button
} from "reactstrap";
import classnames from "classnames";
import General from "components/general/General";
import FeatureApartments from "components/featureApartments/FeatureApartments";
import { useNavigate, useParams } from "react-router-dom";
import isEqual from 'lodash/isEqual';
import "./StyleTable.scss";
import { isAdmin } from "helpers/validateToken";
import { get, post } from "helpers/api_helper";
import Swal from "sweetalert2";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import MediaApartments from "components/mediaApartments/MediaApartments";

import EditerComponent from "components/editor/Editer";
import Slots from "components/slotCommon/Slots";
import { useDispatch ,useSelector } from "react-redux";
import { toggleApartmentEdit } from "store/apartment/actions";

const Apartments = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [addApartment, setAddApartment] = useState(false);
  const [formData, setFormData] = useState({

    video: "",
  });
  const [initialFormData, setInitialFormData] = useState({}); // State for initial form data
  const isApartmentEdit = useSelector((state) => state.apartment.isEditing);

  const navigate = useNavigate();
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("authUser")); // Retrieve the logged-in user's information
  const userId = user.user?.id;
  const dispatch = useDispatch()
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onChangeHandlerFn = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      [data?.name]: data?.value,
    }));

    submitHandler();
  };

  const onInputChange = (e) => {
    const { name, value } = e.target || {}; // Safeguard against undefined e.target
  

  if(!isAdmin){
      setFormData((prevValues) => ({
        ...prevValues,
        owner_id: userId, // Default to current user's ID
      }));
  }
  
    if (name === "neighbourhood") {
      // Handle neighbourhood input
      setFormData((prevValues) => ({
        ...prevValues,
        neighbourhood: value,
      }));
    } else {
      // Handle other fields
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };
  

  const onAddressChange = (evt) => {
    const { name, value } = evt.target;

    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address, // Preserve existing fields in address
        [name.split(".")[1]]: value, // Update only the relevant field (city or street)
      },
    }));
  };

  const submitHandler = () => {
    // Validate required fields
    const requiredFields = [
      "name",
      // Only include "owner_id" if isAdmin is true
      ...(isAdmin ? ["owner_id"] : []),
      "address.city",
      "address.street",
      "zipcode",
      "price",
      "deposit",

      "active",
    
    ]; // Add your required fields
    
    const isEmptyField = requiredFields.some((field) => {
      const value = field.split(".").reduce((o, i) => o[i], formData);
      return !value || (typeof value === 'string' && value.trim() === "");
    });

    if ( !id &&isEmptyField) {
      Swal.fire({
        icon: "warning",
        title: "Please fill in all required fields!",
        showConfirmButton: true,
      });
      return; // Stop submission if fields are empty
    }

    // Check if any changes were made
    const isChanged = !isEqual(initialFormData, formData);

    if (id && !isChanged) {
      Swal.fire({
        icon: "info",
        title: "No changes detected!",
        text: "Please make some changes before submitting.",
    
      });
      return; // Stop submission if no changes are detected
    }

    const token = authHeader();
    const config = { headers: { Authorization: token } };
    const url = id ? `/apartments/${id}` : "/apartments";

    post(url, formData, config)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: res?.message || "Apartment created successfully with media",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          dispatch(toggleApartmentEdit(false))
          setAddApartment(!addApartment);
          navigate("/apartments");
        });
      })
      .catch((error) => {
        console.log("Error:", error);
        Swal.fire({
          position: "center",
          icon: "error",
          text: error.message || "An error occurred while updating the apartment",
          showConfirmButton: true,
        });
      });
  };

  const fetchApartmentsById = async () => {
    try {
      const token = authHeader(); // Fetch the authorization token
      const config = { headers: { Authorization: token } };
      const res = await get(`/apartments/${id}`, config);

      setFormData(res?.data);
      setInitialFormData(res?.data); // Save initial data for comparison
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchApartmentsById();
    }
  }, [id]);
const  editApartment = ()=>{
  dispatch(toggleApartmentEdit(true))
  if(id && isApartmentEdit){
    onChangeHandlerFn()
  }
}

  document.title = "Apartments | SHS Admin";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Apartments"
            breadcrumbItem={"Manage"}
            link="apartments"
          />

          <Row>
            <Col className="col-12">
        
              <Card style={{ padding: "20px" }}>
       {  id  &&     <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                >
               
                      <Button
                        color="primary"
                        onClick={editApartment}
                        // onClick={() => navigate(`/apartments/add`)}
                      >
                     { isApartmentEdit == false? " Edit Apartment":"Update Apartment"}
                      </Button>
                
                </Col>}
                <Row className="justify-content-center">
                  <Col>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => toggle("1")}
                        >
                          GENERAL
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => toggle("2")}
                        >
                          FEATURES
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => toggle("3")}
                        >
                          MEDIA
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "4" })}
                          onClick={() => toggle("4")}
                        >
                          SLOTS
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "5" })}
                          onClick={() => toggle("5")}
                        >
                          OTHERS
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>

                <TabContent activeTab={activeTab} className="mt-4">
                  <TabPane tabId="1">
                    <General
                      values={formData}
                      onInputChange={onInputChange}
                      onAddressChange={onAddressChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <FeatureApartments
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <MediaApartments
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                      onChangeHandlerFn={onChangeHandlerFn}

                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <Slots
                        values={formData}
                        onInputChange={onInputChange}
                        setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <EditerComponent
                      values={formData}
                      onInputChange={onInputChange}
                      onChangeHandlerFn={onChangeHandlerFn}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Apartments;
