import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
} from "reactstrap"
import classnames from "classnames"
import RoomGeneral from "components/general/RoomGeneral"
import ServiceRooms from "components/featureApartments/ServiceRooms"
import { useNavigate, useParams, useLocation } from "react-router-dom"

import { get, post } from "helpers/api_helper"
import Swal from "sweetalert2"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import MediaRooms from "components/mediaApartments/MediaRooms"
import isEqual from 'lodash/isEqual';
import SlotsRooms from "components/slotCommon/SlotsRooms"
// import SlotsRooms from "components/editor/SlotsRooms"

const ManageRooms = () => {
  const [activeTab, setActiveTab] = useState("1")
  const [initialFormData, setInitialFormData] = useState({}); 
  const [formData, setFormData] = useState({})

  const location = useLocation()
  const navigate = useNavigate()

  let FData = location?.state?.Fdata

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const { id } = useParams()
 const residenceId = JSON.parse(localStorage.getItem("residenceId"))

  const onChangeHandlerFn = data => {
    setFormData(prevData => ({
      ...prevData,
      [data?.name]: data?.value,
    }))

    submitHandler()
  }
  const onInputChange = evt => {
    setFormData(prevData => ({
      ...prevData,
      [evt.target.name]: evt?.target?.value,
    }))
  }


  const submitHandler = () => {
    const token = authHeader()

    const config = {
      headers: {
        Authorization: token,
      },
    }
    const requiredFields = ["name",
    
    "number",
   
    "room_type",
    "publish",
   
    "price",
    ]; // Add your required fields
    const isEmptyField = requiredFields.some((field) => {
      const value = field.split(".").reduce((o, i) => o[i], formData);
      return !value || (typeof value === 'string' && value.trim() === "");
    });

    if ( !id &&isEmptyField) {
      Swal.fire({
        icon: "warning",
        title: "Please fill in all required fields!",
        showConfirmButton: true,
      });
      return; // Stop submission if fields are empty
    }

    // Check if any changes were made
    const isChanged = !isEqual(initialFormData, formData);

    if (id && !isChanged) {
      Swal.fire({
        icon: "info",
        title: "No changes detected!",
        text: "Please make some changes before submitting.",
    
      });
      return; // Stop submission if no changes are detected
    }
    const formattedFormData = {
      ...formData,
      owner_id: FData?.owner_id || formData?.owner_id,
      residence_id: FData?.residence_id || formData?.residence_id || residenceId ,
      media: JSON.stringify(formData.media),
    }
    
    // Check if `id` is present to determine the correct URL and method
    const url = id ? `/updateRoom/${id}` : "/addRoom"

    // Call the appropriate method with the URL, formData, and config
    post(url, formattedFormData, config)
      .then(res => {
        Swal.fire({
          position: "center",
          icon: "success",
          title:
            res?.message ||
            (id
              ? "Room updated successfully"
              : "Room created successfully with media"),
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          navigate(
            `/residence/edit/${
              id ? formData.residence_id :  FData.residence_id ||residenceId
            }?data=rooms`
          )
        })
      })
      .catch(error => {
        console.log("errorrrr",error)
        Swal.fire({
          position: "center",
          icon: "error",
          title: id ? "Error updating Room" : "Error creating Room",
          text: error.message || "An error occurred while processing the Room",
          showConfirmButton: true,
        })
      })
  }

  const fetchRoomById = async () => {
    try {
      const token = authHeader() // Fetch the authorization token

      const config = {
        headers: {
          Authorization: token,
        },
      }

      const res = await get(`/viewRoom/${id}`, config)

      setFormData(res?.data)
      setInitialFormData(res?.data); // Save initial data for comparison
    
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    if (id) {
      fetchRoomById()
    }
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Rooms"
            link={`residence/edit/${formData.residence_id}?data=rooms`}
            // onClick={() => navigate(`/residence/edit/${formData.residence_id}`,{ state: { data:'rooms' }})}
            breadcrumbItem={"Manage"}
          />

          <Row>
            <Col className="col-12">
              <Col
                className="col-12 d-flex justify-content-end mt-3 me-3"
                style={{ paddingRight: "20px" }}
              ></Col>
              <Card style={{ padding: "20px" }}>
                <Row className="justify-content-center">
                  <Col>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => toggle("1")}
                        >
                          GENERAL
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => toggle("2")}
                        >
                          SERVICE ROOMS
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => toggle("3")}
                        >
                          SLOTS ROOMS
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "4" })}
                          onClick={() => toggle("4")}
                        >
                          MEDIA
                        </NavLink>
                      </NavItem>
                    
                    </Nav>
                  </Col>
                </Row>

                <TabContent activeTab={activeTab} className="mt-4">
                  <TabPane tabId="1">
                    <RoomGeneral
                      values={formData}
                      Fdata={FData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <ServiceRooms
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>{" "}
                  <TabPane tabId="3">
                    <SlotsRooms
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>{" "}
                  <TabPane tabId="4">
                    <MediaRooms
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                      onChangeHandlerFn={onChangeHandlerFn}
                    />
                  </TabPane>
                  {/* <TabPane tabId="4">
                    <SlotsRooms
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                      onChangeHandlerFn={onChangeHandlerFn}
                    />
                  </TabPane> */}
                </TabContent>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManageRooms
