import React, { useState, useEffect } from "react"
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap"
import PropTypes from "prop-types"

const ServiceRooms = ({ values, onInputChange, setActiveTab }) => {
  const baseUrl = "https://api.shs-app.es/beyondapi/media/icons"

  const featureList = [
    { name: "Air Condition", url: `${baseUrl}/Air conditioner.png` },
    { name: "TV", url: `${baseUrl}/TV.png` },
    { name: "Blender", url: `${baseUrl}/blender.png` },
    { name: "Washer", url: `${baseUrl}/washer.png` },
    { name: "Elevator", url: `${baseUrl}/Elevator.png` },
    { name: "Doorman", url: `${baseUrl}/Doorman.png` },
    { name: "Oven", url: `${baseUrl}/oven.png` },
    { name: "Toaster", url: `${baseUrl}/Toaster.png` },
    { name: "Hot Water", url: `${baseUrl}/Hot Water.png` },
    { name: "Wi-Fi", url: `${baseUrl}/wifi.png` },
    { name: "Dishwasher", url: `${baseUrl}/Dishwasher.png` },
    { name: "Alarm System", url: `${baseUrl}/alarm system.png` },
    { name: "Parking Space", url: `${baseUrl}/Parking Space.png` },
    { name: "Sofa", url: `${baseUrl}/sofa.png` },
    { name: "Coffee machine", url: `${baseUrl}/Coffee machine.png` },
    { name: "Bed", url: `${baseUrl}/Bed.png` },
    { name: "Dryer", url: `${baseUrl}/Dryer.png` },
    { name: "Terrace", url: `${baseUrl}/Terrace.png` },
  ]
  const [features, setFeatures] = useState(values?.services || {})

  useEffect(() => {
    if (values?.services) {
      try {
        const parsedFeatures =
          typeof values.services === "string"
            ? JSON.parse(values.services)
            : values.services

        setFeatures(parsedFeatures)
      } catch (error) {
        console.error("Error parsing features:", error)
      }
    }
  }, [values?.services])

  const handleFeatureChange = (event, url) => {
 
    const { name, checked } = event.target

    // Update the selected features
    setFeatures(prevFeatures => {
      const updatedService = { ...prevFeatures }

      if (checked) {
        // If checked, set the URL for the feature
        updatedService[name] = url
      } else {
        // If unchecked, remove the feature from the list
        delete updatedService[name]
      }

      // Call the parent handler with the updated features
      onInputChange({
        target: {
          name: "services",
          value: updatedService, // Use the updated features
        },
      })

      return updatedService // Return the updated state
    })
  }

  return (
    <div>
      <FormGroup>
        <Label for="service-features">Available Services</Label>
        <Row>
          {featureList.map((feature, index) => (
            <Col md={4} key={index} className="d-flex align-items-center mb-2">
              <FormGroup check inline>
                <Label check className="form-check-label">
                  <Input
                    type="checkbox"
                    name={feature.name}
                    checked={Boolean(features[feature.name])} // Check if the feature is selected
                    onChange={e => handleFeatureChange(e, feature.url)}
                  />
                  <img
                    src={feature.url}
                    alt={feature.name}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  {feature.name}
                </Label>
              </FormGroup>
            </Col>
          ))}
        </Row>
      </FormGroup>

      <Row style={{ marginBottom: "25px", marginTop: "25px" }}>
        <Col md={6}>
          <Button color="secondary" onClick={() => setActiveTab("1")}>
            Back
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={() => setActiveTab("3")}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  )
}

ServiceRooms.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

export default ServiceRooms
