import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap"
import { useNavigate ,useParams} from "react-router-dom"
import { get } from "helpers/api_helper"
import authHeader from "helpers/jwt-token-access/auth-token-header"



const UserGeneral = ({ values, onInputChange, onChangeHandlerFn }) => {
  const [errors, setErrors] = useState({})
  const [allRole, setAllRoles] = useState()



  const navigate = useNavigate()
  const { id } = useParams()


  const validate = () => {
    const newErrors = {}
    const requiredFields = [
      "first_name",
      "last_name",
      "domain",
      "role",
      "zpcode",
      "user_email",
      "user_pass",
      "user_login",
    ]

    requiredFields.forEach(field => {
      const value = field
        .split(".")
        .reduce((acc, part) => acc && acc[part], values)
      if (!value || value.toString().trim() === "") {
        newErrors[field] = "This field is required"
      }
    })

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }
  const fetchAllRoles = async () => {
    const token = authHeader()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const res = await get(`/roles`, config)

      setAllRoles(res.data)
    } catch (error) {
      console.log("an error occured", error)
    }
  }
  const submit = () => {
    if (!validate()) {
      onChangeHandlerFn()
    }
  }
  useEffect(() => {
    fetchAllRoles()
  }, [])

  return (
    <div>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="first_name">
              First Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="first_name"
              type="text"
              id="first_name"
              placeholder="Enter first name"
              onChange={onInputChange}
              value={values?.first_name || ""}
              invalid={!!errors.first_name}
            />
            {errors.first_name && (
              <FormFeedback style={{ color: "red" }}>
                {errors.first_name}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="last_name">
              Last Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="last_name"
              type="text"
              id="last_name"
              placeholder="Enter last name"
              onChange={onInputChange}
              value={values?.last_name || ""}
              invalid={!!errors.last_name}
            />
            {errors.last_name && (
              <FormFeedback style={{ color: "red" }}>
                {errors.last_name}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="user_email">
              Email <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="user_email"
              type="text"
              id="user_email"
              placeholder="Enter email"
              onChange={onInputChange}
              value={values?.user_email || ""}
              invalid={!!errors.user_email}
            />
            {errors.user_email && (
              <FormFeedback style={{ color: "red" }}>
                {errors.user_email}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="user_pass">
              Password <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="user_pass"
              type="text"
              id="user_pass"
              placeholder="Enter password"
              onChange={onInputChange}
              value={values?.user_pass || ""}
              invalid={!!errors.user_pass}
            />
            {errors.user_pass && (
              <FormFeedback style={{ color: "red" }}>
                {errors.user_pass}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="domain">
              Domain <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="domain"
              type="text"
              id="domain"
              placeholder="Enter domain"
              onChange={onInputChange}
              value={values?.domain || ""}
              invalid={!!errors.domain}
            />
            {errors.domain && (
              <FormFeedback style={{ color: "red" }}>
                {errors.domain}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="role">
              Role <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="select"
              name="role"
              id="role"
              value={values?.role || ""}
              onChange={onInputChange}
              invalid={!!errors.role}
            >
              {/* "Select Role" is shown only when no role is selected */}
              {!values?.role && <option value="">Select Role</option>}

              {allRole?.map(item => {
                return (
                  <>
                    <option key={item?.role_id} value={item?.role_id}>
                      {item?.role_name}
                    </option>
                  </>
                )
              })}
            </Input>
            {errors.role && (
              <FormFeedback style={{ color: "red" }}>
                {errors.role}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Col md={4}>
        <FormGroup>
          <Label for="user_login">
            User Name <span style={{ color: "red" }}>*</span>
          </Label>
          <Input
            name="user_login"
            type="text"
            id="user_login"
            placeholder="Enter user name"
            value={values?.user_login || ""}
            onChange={onInputChange}
            invalid={!!errors.user_login}
          />
          {errors.user_login && (
            <FormFeedback style={{ color: "red" }}>
              {errors.user_login}
            </FormFeedback>
          )}
        </FormGroup>
      </Col>
      <Row>
        <Col md={6}>
          <Button color="secondary" onClick={() => navigate("/users")}>
            Back
          </Button>
        </Col>
<Col md={6} className="d-flex justify-content-end">
          <Button type="button" color="primary" onClick={submit}>
     {  id?"Update":"Submit"     }
          </Button>
        </Col>
      </Row>
    </div>
  )
}

// Define PropTypes
UserGeneral.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  onChangeHandlerFn: PropTypes.func.isRequired,
}

export default UserGeneral
