import React, { useState, useEffect } from "react";
import { Input, Label, Row, Col, Button, FormGroup, FormFeedback } from "reactstrap";
import { get } from "helpers/api_helper";
import PropTypes from "prop-types";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {  useSelector } from 'react-redux';
const Slots = ({ values, onInputChange, setActiveTab }) => {
  const [rows, setRows] = useState([]); // Slot rows state
  const [allSlot, setAllSlot] = useState([]); // All available slots from API
 
  const [selectedSlots, setSelectedSlots] = useState([]); // To track selected slots
  const [errors, setErrors] = useState({});
  const isApartmentEdit = useSelector((state) => state.apartment.isEditing);
  const validate = () => {
    const newErrors = {};
    rows?.forEach((row, idx) => {
      if (!row.slot) {
        newErrors[`slot_${idx}`] = "Slot is required";
      }
    
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getAllSlot = async () => {
    const token = authHeader();
    const config = { headers: { Authorization: token } };

    try {
      const res = await get(`/slots`, config);
      setAllSlot(res.data);
    } catch (error) {
      console.log("An error occurred", error);
    }
  };

  const handleAddRow = () => {
    const newRow = { slot: "", booking_status: "" };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (idx) => {
    const rowToRemove = rows[idx];

    // Remove the slot from selectedSlots
    const updatedSelectedSlots = selectedSlots.filter((slot) => slot !== rowToRemove.slot);

    const updatedRows = rows?.filter((_, i) => i !== idx);
    setRows(updatedRows);
    setSelectedSlots(updatedSelectedSlots);

    // Update the slots_taken in the form state
    const stringifiedRows = JSON.stringify(updatedRows);
    onInputChange({ target: { name: "slots_taken", value: stringifiedRows } });
  };

  const handleRowChange = (idx, field, value) => {
    // Update the rows with the changed field and value
    const updatedRows = rows?.map((row, i) => {
      if (i === idx) {
        // If the slot is being updated, set booking_status to "Available"
        if (field === "slot" && value) {
          return { ...row, [field]: value, booking_status: "Available" }; // Update booking_status
        }
        return { ...row, [field]: value }; // Otherwise, just update the field
      }
      return row;
    });
  
    // Handle slot-specific logic
    if (field === "slot") {
      const previousSlot = rows[idx].slot;
      const updatedSelectedSlots = previousSlot
        ? selectedSlots.filter((slot) => slot !== previousSlot)
        : selectedSlots;
  
      if (value) {
        setSelectedSlots([...updatedSelectedSlots, value]);
      } else {
        setSelectedSlots(updatedSelectedSlots);
      }
    }
  
    // Update the rows in state
    setRows(updatedRows);
  
    // Update any error state if necessary
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${field}_${idx}`]: value ? "" : prevErrors[`${field}_${idx}`],
    }));
  
    // Pass the updated rows to onInputChange
    const stringifiedRows = JSON.stringify(updatedRows);
    onInputChange({ target: { name: "slots_taken", value: stringifiedRows } });
  

  };
  



  useEffect(() => {
    getAllSlot();
  
    const parseSlots = (slotsTaken) => {
      if (Array.isArray(slotsTaken)) {
        return slotsTaken;
      }
      try {
        return JSON.parse(slotsTaken) || [{ slot: "", booking_status: "" }];
      } catch (error) {
        console.error("Error parsing slots_taken:", error);
        return [{ slot: "", booking_status: "" }];
      }
    };
   
  
    const parsedRows = parseSlots(values.slots_taken);
    setRows(parsedRows);

  }, [values.slots_taken, values.textAboutSlot]);

  const getAvailableSlots = (currentRowSlot) => {
    return allSlot.filter((slot) => {
      return !selectedSlots.includes(slot.id) || slot.id === currentRowSlot;
    });
  };

  const handleNext = () => {
    if (validate()) {
      setActiveTab("5");
    }
  };
  const handleEditorChange = (value, name) => {
    onInputChange({ target: { name, value } });
  };
  const formats = [
    "font", "size",
    "bold", "italic", "underline", "strike",
    "color", "background",
    "script",
    "header", "blockquote", "code-block",
    "indent", "list",
    "direction", "align",
    "link", "image", "video", "formula",
  ];

  // Modules for the editor
  const modules = {
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      [{ 'header': '1'}, {'header': '2'}, { 'script': 'sub'}, { 'script': 'super' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
      ['blockquote', 'code-block'],
      [{ 'direction': 'rtl' }, { 'align': [] }],
      ['link', 'image', 'video', 'formula'],
      ['clean']
    ],
  };
  return (
    <div>
      {rows?.map((row, idx) => (
        <div id={"nested" + idx} key={idx}>
          <Row className="mb-3">
            <Col md={5}>
              <FormGroup>
                <Label>
                  Slot <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="select"
                  name="slot"
                  onChange={(e) => handleRowChange(idx, "slot", e.target.value)}
                  value={row.slot || ""}
                  invalid={!!errors[`slot_${idx}`]}
                  disabled={!isApartmentEdit}
                >
                  <option value="">Select Slot</option>
                  {getAvailableSlots(row.slot).map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.slot}
                    </option>
                  ))}
                </Input>
                {errors[`slot_${idx}`] && (
                  <FormFeedback style={{ color: "red" }}>
                    {errors[`slot_${idx}`]}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>

            <Col md={5}>
              <FormGroup>
                <Label>
                  Status <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="select"
                  name="booking_status"
                  onChange={(e) => handleRowChange(idx, "booking_status", e.target.value)}
                  value={row.booking_status || "Available"}
                  disabled={!isApartmentEdit}
                  // invalid={!!errors[`booking_status_${idx}`]}
                >
                  {/* <option value="">Select availability</option> */}
                  <option value="Available">Available</option>
                  <option value="Unavailable">Unavailable</option>
                </Input>
                {/* {errors[`booking_status_${idx}`] && (
                  <FormFeedback style={{ color: "red" }}>
                    {errors[`booking_status_${idx}`]}
                  </FormFeedback>
                )} */}
              </FormGroup>
            </Col>

            <Col md={2} className="d-flex align-items-center">
              <Button
                color="danger"
                onClick={() => handleRemoveRow(idx)}
                className="mt-3"
                disabled={!isApartmentEdit}
              >
                Remove
              </Button>
            </Col>
          </Row>
        </div>
      ))}

      <Button color="primary" onClick={handleAddRow} className="mt-3"  disabled={!isApartmentEdit}>
        Add another slot
      </Button>

      <Row className="mt-3">
        <Col md={12}>
          <FormGroup>
            <Label for="description">Description</Label>
            <ReactQuill
            
              value={values?.slot_comment || ""}
              onChange={(value) => handleEditorChange(value, "slot_comment")}
              placeholder="Enter comments for slot"
              formats={formats}
              modules={modules}
              readOnly={!isApartmentEdit}

              
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Button color="secondary" onClick={() => setActiveTab("3")}>
            Back
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

Slots.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default Slots;
