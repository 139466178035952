import PropTypes from "prop-types"
import React from "react"
import { Routes, Route } from "react-router-dom"
import { connect } from "react-redux"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import UserProfile from "pages/Authentication/user-profile"
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"

import ForgetPwd from "pages/Authentication/ForgetPassword"

import Dashboard from "pages/Dashboard/index"

import Pages404 from "pages/Utility/pages-404"
import Pages500 from "pages/Utility/pages-500"

import Apartments from "pages/Apartments/list"
import ManageApartment from "pages/Apartments/manage"

import Invoice from "pages/invoice/Invoice"

import Bookings from "pages/bookings/Bookings"
import BookingsDetail from "pages/bookings/Bookingsdetail"
import User from "pages/user/Userslisting"
// import Rooms from "pages/rooms/Rooms"

import Residence from "pages/residenceis/Residencelistig"
import ManageUser from "pages/user/ManageUser"
// import AddRole from "components/addrole/AddRole"
import AddRolslisting from "pages/addRols/AddRolslisting"
import ResidenceManage from "pages/residenceis/ResidenceManage"

import RoleManager from "pages/addRols/RoleManage"
import ManageRooms from "pages/rooms/ManageRooms"
import RoomType from "pages/manageAbleRoomType/RoomType"
import ManageRoomType from "pages/manageAbleRoomType/ManageRoomType"
import Slots from "pages/manageAbleSlots/Slots"
import ManageSlots from "pages/manageAbleSlots/ManageSlots"
import NeighourhoodListing from "pages/manageAbleNeighborhood/NeighourhoodListing"
import ManageNeighorhood from "pages/manageAbleNeighborhood/ManageNeighorhood"

const Home = () => {
  const userRoutes = [
    { path: "/dashboard", component: <Dashboard /> },
    { path: "/settings/role", component: <AddRolslisting /> },
    { path: "/settings/role/add", component: <RoleManager /> },
    { path: "/settings/role/edit/:id", component: <RoleManager /> },
    { path: "/profile", component: <UserProfile /> },
    { path: "/apartments", component: <Apartments /> },
    { path: "/apartments/add", component: <ManageApartment /> },
    { path: "/apartments/edit/:id", component: <ManageApartment /> },

    { path: "/settings/users", component: <User /> },
    { path: "/settings/users/add", component: <ManageUser /> },
    { path: "/settings/users/edit/:id", component: <ManageUser /> },
    { path: "/invoice", component: <Invoice /> },
    { path: "/bookings", component: <Bookings /> },
    { path: "/booking/:id", component: <BookingsDetail /> },

    // { path: "/rooms", component: <Rooms />, },
    { path: "/residence/rooms/add", component: <ManageRooms /> },
    { path: "/residence/rooms/edit/:id", component: <ManageRooms /> },

    { path: "/residence", component: <Residence /> },
    { path: "/residence/add", component: <ResidenceManage /> },
    { path: "/residence/edit/:id", component: <ResidenceManage /> },
    { path: "/settings/room-type", component: <RoomType /> },
    { path: "/settings/room-type/add", component: <ManageRoomType /> },
    { path: "/settings/room-type/edit/:id", component: <ManageRoomType /> },
    { path: "/settings/slots", component: <Slots /> },
    { path: "/settings/slots/add", component: <ManageSlots /> },
    { path: "/settings/slots/edit/:id", component: <ManageSlots /> },
    { path: "/settings/neighbourhood", component: <NeighourhoodListing /> },
    { path: "/settings/neighbourhood/add", component: <ManageNeighorhood /> },
    {
      path: "/settings/neighbourhood/edit/:id",
      component: <ManageNeighorhood />,
    },

    // this route should be at the end of all other routes
    { path: "/", component: <Dashboard /> },
  ]

  const authRoutes = [
    { path: "/logout", component: <Logout /> },
    { path: "/login", component: <Login /> },
    { path: "/forgot-password", component: <ForgetPwd /> },

    { path: "/pages-404", component: <Pages404 /> },
    { path: "/pages-500", component: <Pages500 /> },
  ]

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {authRoutes.map((route, idx) => (
            <Route
              path={`${route.path}`}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {userRoutes.map((route, idx) => {
            return (
              <Route
                path={`${route.path}`}
                element={
                  <Authmiddleware requiredRole={route.requiredRole}>
                    {route.component}
                  </Authmiddleware>
                }
                key={idx}
                exact={true}
              />
            )
          })}
        </Route>
      </Routes>
    </React.Fragment>
  )
}

Home.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(Home)
