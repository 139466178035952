import React, {} from "react"

import { Row, Col, FormGroup, Label,Button } from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for React Quill
import PropTypes from "prop-types";
import "./Style.scss"
// import { hasPermission } from "helpers/validateToken"
import {  useParams, } from "react-router-dom"
// import { isAdmin } from "helpers/validateToken";
import {  useSelector } from 'react-redux';

const EditerComponent = ({ onChangeHandlerFn,values, onInputChange,setActiveTab }) => {
  // const [isPermission, setIsPermission] = useState(false)

  const { id } = useParams()

  const isApartmentEdit = useSelector((state) => state.apartment.isEditing);


  // Function to handle editor change
  const handleEditorChange = (value, name) => {
    onInputChange({ target: { name, value } });
  };

  // Formats for the editor
  const formats = [
    "font", "size",
    "bold", "italic", "underline", "strike",
    "color", "background",
    "script",
    "header", "blockquote", "code-block",
    "indent", "list",
    "direction", "align",
    "link", "image", "video", "formula",
  ];

  // Modules for the editor
  const modules = {
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      [{ 'header': '1'}, {'header': '2'}, { 'script': 'sub'}, { 'script': 'super' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
      ['blockquote', 'code-block'],
      [{ 'direction': 'rtl' }, { 'align': [] }],
      ['link', 'image', 'video', 'formula'],
      ['clean']
    ],
  };

// for checking the permission


  // useEffect(() => {
  //   // Get permissions and user role from localStorage
  //   const obj = JSON.parse(localStorage.getItem("permission"))
  //   const permissions = JSON.parse(obj?.permission)


  //   // Check if the user has the permission to access 'apartments'
  //   const hasPerm = hasPermission(
  //     permissions,
  //     "Apartments",
  //     ["Read-only", "Read-write"],
  //     isAdmin
  //   )

  //   // If the user is Admin, they should have full access
  //   if (isAdmin) {
  //     setIsPermission(true) // Admin has access to everything
  //   } else if (!hasPerm) {
  //     navigate("/pages-404") // Redirect if no permission
      
  //     setIsPermission(false) // Set permission state to false
  //   } else {
  //     // Handle other permissions like 'Read-only' and 'Read-write'
  //     const role = permissions["Apartments"]
  //     if (role === "Read-only") {
  //       setIsPermission(false) // Limited access
  //     } else if (role === "Read-write") {
  //       setIsPermission(true) // Full access
  //     } else {
  //       setIsPermission(false) // Default case
  //     }
  //   }
  // }, [])

  return (
<>
<Row>
<Col md={12}>
        <FormGroup>
          <Label for="description">Description</Label>
          <ReactQuill
            value={values.description}
            onChange={(value) => handleEditorChange(value, "description")}
            placeholder="Enter Description"
            formats={formats}
            modules={modules}
            readOnly={!isApartmentEdit}

          />
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup>
          <Label for="rules">Rules</Label>
          <ReactQuill
            value={values.rules}
            onChange={(value) => handleEditorChange(value, "rules")}
            placeholder="Enter Rules"
            formats={formats}
            modules={modules}
            className="react-quill-container" 
            readOnly={!isApartmentEdit}
            
          />
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup>
          <Label for="notes">Notes</Label>
          <ReactQuill
            value={values.notes}
            onChange={(value) => handleEditorChange(value, "notes")}
            placeholder="Enter Notes"
            formats={formats}
            modules={modules}
            readOnly={!isApartmentEdit}

          />
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup>
          <Label for="payment_terms">Payment Terms</Label>
          <ReactQuill
            value={values.payment_terms}
            onChange={(value) => handleEditorChange(value, "payment_terms")}
            placeholder="Enter Payment Terms"
            formats={formats}
            modules={modules}
            readOnly={!isApartmentEdit}

          />
        </FormGroup>
      </Col>
  
 
   
    </Row>
   
     <Row style={{marginBottom:"25px"}}>
  <Col md={6}>
    <Button color="secondary" onClick={() => setActiveTab("3")}>Back</Button>
  </Col>
  <Col md={6} className="d-flex justify-content-end">
{ !id&& <Button type="button" color="primary" onClick={() => onChangeHandlerFn() } style={{marginBottom:"50px"}}>
     Submit
     </Button>}
  </Col>
</Row>
</>

  );
};

EditerComponent.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onChangeHandlerFn: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,


};

export default EditerComponent;
