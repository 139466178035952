import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"

// import "./StyleTable.scss";
import Swal from "sweetalert2"
import { get, del } from "helpers/api_helper"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import { useNavigate } from "react-router-dom"
import { hasPermission } from "helpers/validateToken"
import Loader from "components/loader/Loader"
import { isAdmin } from "helpers/validateToken"
import CustomPagination from "components/pagination/CustomPagination"
const Users = () => {
  const [filterType, setFilterType] = useState("")
  const [filterValue, setFilterValue] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [sortBY, setSortBY] = useState("id")
  const [order, setOrder] = useState("DESC")
  const [selectedRows, setSelectedRows] = useState({})
  const [loading, setLoading] = useState(true);
  const[ totalItems,setTotalItems] =useState(0)

  const [limit, setLimit] = useState(10)
  const [isPermission, setIsPermission] = useState(false)

  const navigate = useNavigate()
  const [data, setData] = useState({
    columns: [
      {
        label: "ID",
        field: "idClick",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("id"),
      },
      {
        label: " NAME",
        field: "first_name",
        sort: "asc",
        width: 100,
        onClick: () => handleSort("first_name"),
      },
      {
        label: "EMAIL",
        field: "user_email",
        sort: "asc",
        width: 100,
        onClick: () => handleSort("user_email"),
      },

      {
        label: "INFORMATION",
        field: "information",
        sort: "asc",
        width: 270,
        onClick: () => handleSort("information"),
      },
    ],
    rows: [],
  })
  const handleCheckboxChange = id => {
    setSelectedRows(prevSelected => ({
      ...prevSelected,
      [id]: !prevSelected[id],
    }))
  }

  const filterData = () => {
    return data?.rows?.filter(row => {
      const value = row[filterType] || ""
      switch (filterType) {
        case "id":
        case "user_email":
        case "role":
        case "domain":
        case "first_name":
          return value
            .toString()
            .toLowerCase()
            .includes(filterValue.toString().toLowerCase())
        default:
          return true
      }
    })
  }

  const filteredData = {
    columns: [
      ...(isPermission
        ? [
            {
              label: "",
              field: "select",
              sort: "disabled",
              attributes: {
                "data-attributes": "select",
              },
            },
          ]
        : []),
      ...data.columns,
    ],
    rows: filterData()?.map(row => ({
      ...row,
      first_name: `${row.first_name} ${row.last_name}`,
      // domain: (
      //   <a href={`https://${row.domain}`} target="_blank" rel="noopener noreferrer">
      //     {row.domain}
      //   </a>
      // ),
      idClick: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/settings/users/edit/${row.id}`)
          }}
          dangerouslySetInnerHTML={{
            __html: `
          <div style="color: #45c4ad;">${row.id}</div>
   
        
          `,
          }}
        />
      ),
     
      information: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/settings/users/edit/${row.id}`)
          }}
          dangerouslySetInnerHTML={{
            __html: `
          <div style="color: #45c4ad;">${row.first_name} ${row.last_name}</div>
   
          <div>Domain: <span style="color: green;">
        
              ${row.domain}
           </span>
          </div>
          `,
          }}
        />
      ),
      select: isPermission ? (
        <Input
          type="checkbox"
          checked={!!selectedRows[row.id]}
          onChange={() => handleCheckboxChange(row.id)}
        />
      ) : null,
    })),
  }
  const handleSort = field => {
    // const direction = sortBY === field && order === "ASC" ? "DESC" : "ASC";
    setSortBY(field)
    setOrder(prevOrder => (prevOrder === "ASC" ? "DESC" : "ASC"))
    fetchUser(currentPage, limit, field, order)
  }

  const fetchUser = async (page, limit, sortBY, order) => {
    setLoading(true); 
    try {
      const token = authHeader() // Fetch the authorization token

      const config = {
        headers: {
          Authorization: token,
        },
      }

      const res = await get(
        `/users?page=${page}&limit=${limit}&sortBY=${sortBY}&order=${order}`,
        config
      )

      setTotalPages(res?.pagination?.totalPages || 1)
      setTotalItems(res?.pagination?.totalItems||"")


      setData({
        columns: data.columns, // Keep existing columns
        rows: res?.data,
      })
    } catch (error) {
      console.log("error", error)
    }finally {
      setLoading(false); // Set loading to false after the fetch
    }
  }

  const deleteUser = async () => {
    const token = authHeader() // Fetch the authorization token
    const idsToDelete = Object.keys(selectedRows).filter(id => selectedRows[id])

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            Authorization: token,
          },
        }

        try {
          await Promise.all(idsToDelete.map(id => del(`/users/${id}`, config)))

          Swal.fire({
            title: "Deleted!",
            text: "User Deleted Successfully",
            icon: "success",
            timer: 3000, // 3 seconds timer
          })
          setSelectedRows({})
          fetchUser(currentPage, limit, sortBY, order)
        } catch (error) {
   
          Swal.fire({
            title: "Error!",
            text: "An error occurred while deleting the User.",
            icon: "error",
          })
        }
      }
    })
  }
  const selectedCount = Object.keys(selectedRows).filter(id => selectedRows[id]).length;

  useEffect(() => {
    // Get permissions and user role from localStorage
    const obj = JSON.parse(localStorage.getItem("permission"))
    const permissions = JSON.parse(obj?.permission)



    // Check if the user has the permission to access 'residence'
    const hasPerm = hasPermission(
      permissions,
      "Users",
      ["Read-only", "Read-write"],
      isAdmin
    )

    // If the user is Admin, they should have full access
    if (isAdmin) {
      setIsPermission(true) // Admin has access to everything
    } else if (!hasPerm) {
      navigate("/pages-404") // Redirect if no permission
      setIsPermission(false) // Set permission state to false
    } else {
      // Handle other permissions like 'Read-only' and 'Read-write'
      const role = permissions["Users"]
      if (role === "Read-only") {
        setIsPermission(false) // Limited access
      } else if (role === "Read-write") {
        setIsPermission(true) // Full access
      } else {
        setIsPermission(false) // Default case
      }
    }
  }, [])
  useEffect(() => {
    fetchUser(currentPage, limit, sortBY, order)
  }, [currentPage, limit, sortBY, order])

  const handleTableChange = newPage => {
    setCurrentPage(newPage)
  }

  document.title = "Users | SHS Admin"

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
        <Breadcrumbs maintitle="Home" title="Users" breadcrumbItem="List" link="settings/users" />

          <Row>
            <Col className="col-12">
              <Card>
                <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                >
                  {Object.keys(selectedRows).some(id => selectedRows[id]) ? (
                    <Button color="danger" onClick={deleteUser}>
                      Delete Selected {selectedCount}
                    </Button>
                  ) : (
                    isPermission && (
                      <Button
                        color="primary"
                        onClick={() => navigate(`/settings/users/add`)}
                      >
                        Add User
                      </Button>
                    )
                  )}
                </Col>
{   loading ? <Loader/>  :                           <CardBody>
                  <Row className="mb-3">
                    <Col md={2}>
                      <FormGroup>
                        <Label for="filterType">Filter By</Label>
                        <Input
                          type="select"
                          id="filterType"
                          value={filterType}
                          onChange={e => setFilterType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="id">By ID</option>
                          <option value="first_name">By Name</option>

                          <option value="user_email">By Email</option>
                          <option value="role">By Role </option>
                          <option value="domain">By domain</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    {filterType && (
                      <Col md={2}>
                        <FormGroup>
                          <Label for="filterValue">Filter Value</Label>
                          <Input
                            type="text"
                            id="filterValue"
                            placeholder={`Enter ${filterType}`}
                            value={filterValue}
                            onChange={e => setFilterValue(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    searching={false}
                    data={filteredData}
                    paging={false}
                  />
                { totalItems >limit && <div className="d-flex justify-content-between align-items-center mt-3">
                    <FormGroup>
                      <Label for="limitSelect">Items per Page</Label>
                      <Input
                        type="select"
                        id="limitSelect"
                        value={limit}
                        onChange={e => {
                          setLimit(parseInt(e.target.value))
                          setCurrentPage(1) // Reset to the first page when limit changes
                        }}
                      >
                      

                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </Input>
                    </FormGroup>
                    <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleTableChange ={handleTableChange}
          />
                  </div>}
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Users
