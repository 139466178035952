import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";

import {  useParams} from "react-router-dom"
import ReactQuill from "react-quill";

const RoomTypeCompo = ({ values, onInputChange, onSubmit }) => {

  // State for validation errors
  const [errors, setErrors] = useState({});



  const { id } = useParams()

  // Handle Quill editor changes for description
  const handleEditorChange = (value, name) => {
    onInputChange({ target: { name, value } });
  };

  // Validation function
  const validate = () => {
    const newErrors = {};
    const requiredFields = ["roomtype", ];

    requiredFields.forEach((field) => {
      const value = field.split(".").reduce((acc, part) => acc && acc[part], values);
      if (!value || (Array.isArray(value) && value.length === 0)) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  const modules = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      [{ header: "1" }, { header: "2" }, { script: "sub" }, { script: "super" }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
      [{ direction: "rtl" }, { align: [] }],
      ["link", "image", "video", "formula"],
      ["clean"],
    ],
  };

  // Handle form submit
  const handleNext = () => {
    if (validate()) {
      onSubmit();
    }
  };

  return (
    <Col className="col-12">
      <Row>
        <Col md={12}>
          <FormGroup>
            <Label for="roomtype">
              Room Type <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="roomtype" // Add name attribute
              type="text"
              id="roomtype"
              placeholder="Enter room type"
              onChange={onInputChange} // Use onInputChange here
              value={values?.roomtype || ""}
              invalid={!!errors.roomtype}
            />
            {errors.roomtype && (
              <FormFeedback style={{ color: "red" }}>
                {errors.roomtype}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>

        <Col md={12}>
          <FormGroup>
            <Label for="description">Description</Label>
            <ReactQuill
              value={values.description || ""} // Handle default value
              onChange={(value) => handleEditorChange(value, "description")}
              placeholder="Enter Description"
              formats={formats}
              modules={modules}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}></Col>
   <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={handleNext}>
          { id?"Update": "Submit"}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

// Define PropTypes
RoomTypeCompo.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RoomTypeCompo;
