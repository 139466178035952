import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import CustomPagination from "components/pagination/CustomPagination"
import Swal from "sweetalert2"
import { get, del } from "helpers/api_helper"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import { useNavigate } from "react-router-dom"
import { hasPermission } from "helpers/validateToken"
import moment from "moment"
import Loader from "components/loader/Loader"
import { isAdmin } from "helpers/validateToken"
const NeighourhoodListing = () => {
  const [filterType, setFilterType] = useState("")
  const [filterValue, setFilterValue] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const[ totalItems,setTotalItems] =useState(0)
  
  const [sortBY, setSortBY] = useState("id")
  const [selectedRows, setSelectedRows] = useState({})
  const [loading, setLoading] = useState(true);

  const [order, setOrder] = useState("DESC")
  const [limit, setLimit] = useState(10)
  const [isPermission, setIsPermission] = useState(false)
  const navigate = useNavigate()

  // Function to format permissions object

  const [data, setData] = useState({
    columns: [
      {
        label: "ID",
        field: "id",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("id"),
      },
      {
        label: "NEIGHBOURHOOD",
        field: "neighborhood",
        sort: "asc",
        width: 100,
        onClick: () => handleSort("role_name"),
      },
      {
        label: "CREATED AT",
        field: "createdAt",
        sort: "asc",
        width: 100,
        onClick: () => handleSort("permissions"),
      },
    ],
    rows: [],
  })

  const filterData = () => {
    return data?.rows?.filter(row => {
      const value = row[filterType] || ""
      return value
        .toString()
        .toLowerCase()
        .includes(filterValue.toString().toLowerCase())
    })
  }
  const handleCheckboxChange = roomtype_id => {
    setSelectedRows(prevSelected => ({
      ...prevSelected,
      [roomtype_id]: !prevSelected[roomtype_id],
    }))
  }

  const filteredData = {
    columns: [
      ...(isPermission
        ? [
            {
              label: "",
              field: "select",
              sort: "disabled",
              attributes: {
                "data-attributes": "select",
              },
            },
          ]
        : []),
      ...data.columns,
    ],
    rows: filterData()?.map(row => ({
      ...row,
      select: isPermission ? (
        <Input
          type="checkbox"
          checked={!!selectedRows[row.id]}
          onChange={() => handleCheckboxChange(row.id)}
        />
      ) : null,
      id: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/settings/neighbourhood/edit/${row.id}`)
          }}
          dangerouslySetInnerHTML={{
            __html: `
            <div  style="color: #45c4ad;">${row?.id} </div>
        
          `,
          }}
        />
      ),
      neighborhood: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/settings/neighbourhood/edit/${row.id}`)
          }}
          dangerouslySetInnerHTML={{
            __html: `
            <div  style="color: #45c4ad;">${row.neighborhood} </div>
        
          `,
          }}
        />
      ),
      createdAt: (
        <div
         
          dangerouslySetInnerHTML={{
            __html: `
            <div >${moment(row.createdAt).format(
              "LL"
            )} </div>
        
          `,
          }}
        />
      ),
    })),
  }

  const handleSort = field => {
    setSortBY(field)
    setOrder(prevOrder => (prevOrder === "ASC" ? "DESC" : "ASC"))
    fetchAllNeighorhood(currentPage, limit, field, order)
  }

  const fetchAllNeighorhood = async (page, limit, sortBY, order) => {
    setLoading(true)
    try {
      const token = authHeader()
      const config = {
        headers: {
          Authorization: token,
        },
      }

      const res = await get(
        `/neighborhood?page=${page}&limit=${limit}&sortBY=${sortBY}&order=${order}`,
        config
      )

      setData({
        columns: data.columns,
        rows: res?.data,
      })
      setTotalPages(res?.pagination?.totalPages || 1)
      setTotalItems(res?.pagination?.totalNeighborhoods||"")

    } catch (error) {
      console.log("error", error)
    }finally{
      setLoading(false)
    }
  }

  const deleteUser = async () => {
    const token = authHeader()
    const idsToDelete = Object.keys(selectedRows).filter(id => selectedRows[id])

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            Authorization: token,
          },
        }
        try {
          const res = await Promise.all(
            idsToDelete.map(id => del(`/neighborhood/${id}`, config))
          )

          Swal.fire({
            title: "Deleted!",
            text: res[0]?.message,
            icon: "success",
            timer: 3000,
          })
          setSelectedRows({})
          fetchAllNeighorhood()
        } catch (error) {
      
          Swal.fire({
            title: "Error!",
            text: "An error occurred while deleting the Room Type.",
            icon: "error",
          })
        }
      }
    })
  }
  const selectedCount = Object.keys(selectedRows).filter(id => selectedRows[id]).length;

  useEffect(() => {
    // Get permissions and user role from localStorage
    const obj = JSON.parse(localStorage.getItem("permission"))
    const permissions = JSON.parse(obj?.permission)

    // Assuming the 'role' is stored in localStorage or somewhere in the user context


    // Check if the user has the permission to access 'residence'
    const hasPerm = hasPermission(
      permissions,
      "AddNeighbourhood",
      ["Read-only", "Read-write"],
      isAdmin
    )

    // Log permission result for debugging

    // If the user is Admin, they should have full access
    if (isAdmin) {
      setIsPermission(true) // Admin has access to everything
    } else if (!hasPerm) {
      navigate("/pages-404") // Redirect if no permission
      setIsPermission(false) // Set permission state to false
    } else {
      // Handle other permissions like 'Read-only' and 'Read-write'
      const role = permissions["AddNeighbourhood"]
      if (role === "Read-only") {
        setIsPermission(false) // Limited access
      } else if (role === "Read-write") {
        setIsPermission(true) // Full access
      } else {
        setIsPermission(false) // Default case
      }
    }
  }, [])


  useEffect(() => {
    fetchAllNeighorhood(currentPage, limit, sortBY, order)
  }, [currentPage, limit, sortBY, order])

  const handleTableChange = newPage => {
    setCurrentPage(newPage)
  }

  document.title = "Neighbourhood | SHS Admin"

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Neighbourhood"
            breadcrumbItem={"List"}
            link="settings/neighbourhood" 
          />

          <Row>
            <Col className="col-12">
              <Card>
                <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                >
                  {Object.keys(selectedRows).some(id => selectedRows[id]) ? (
                    <Button color="danger" onClick={deleteUser}>
                      Delete Selected {selectedCount}
                    </Button>
                  ) : (
                    isPermission && (
                      <Button
                        color="primary"
                        onClick={() => navigate(`/settings/neighbourhood/add`)}
                      >
                        Add Neighbourhood
                      </Button>
                    )
                  )}
                </Col>
               {  loading ? <Loader/>  :     <CardBody>
                  <Row className="mb-3">
                    <Col md={2}>
                      <FormGroup>
                        <Label for="filterType">Filter By</Label>
                        <Input
                          type="select"
                          id="filterType"
                          value={filterType}
                          onChange={e => setFilterType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="id">By ID</option>
                          <option value="neighborhood">By Neighbourhood</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    {filterType && (
                      <Col md={2}>
                        <FormGroup>
                          <Label for="filterValue">Filter Value</Label>
                          <Input
                            type="text"
                            id="filterValue"
                            placeholder={`Enter ${filterType}`}
                            value={filterValue}
                            onChange={e => setFilterValue(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    searching={false}
                    data={filteredData}
                    paging={false}
                  />
          {totalItems>limit &&        <div className="d-flex justify-content-between align-items-center mt-3">
                    <FormGroup>
                      <Label for="limitSelect">Items per Page</Label>
                      <Input
                        type="select"
                        id="limitSelect"
                        value={limit}
                        onChange={e => {
                          setLimit(parseInt(e.target.value))
                          setCurrentPage(1)
                        }}
                      >
                       
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </Input>
                    </FormGroup>
                    <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleTableChange ={handleTableChange}
          />
                  </div>}
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NeighourhoodListing
