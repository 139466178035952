import React, { useState, useEffect } from "react"
import { Row, Col, FormGroup, Label, Input, Button, FormText } from "reactstrap"
import PropTypes from "prop-types"
import {  useSelector } from 'react-redux';


const FeatureApartments = ({ values, onInputChange, setActiveTab }) => {
  const [selectedType, setSelectedType] = useState(
    values?.interior_exterior || ""
  )
  const [features, setFeatures] = useState(values?.features || {})
  const [errors, setErrors] = useState({})
  const residenceEdit = useSelector((state) => state.residence.isEditing);

  // List of features
  const baseUrl = "https://api.shs-app.es/beyondapi/media/icons";

  const featureList = [
      { name: "Air Condition", url: `${baseUrl}/Air conditioner.png` },
      { name: "TV", url: `${baseUrl}/TV.png` },
      {name:"Kitchen",url:` ${baseUrl}/Kitchen.png`},
      { name: "Blender", url: `${baseUrl}/blender.png` },
      { name: "Washer", url: `${baseUrl}/washer.png` },
      { name: "Elevator", url: `${baseUrl}/Elevator.png` },
      { name: "Doorman", url: `${baseUrl}/Doorman.png` },
      { name: "Oven", url: `${baseUrl}/oven.png` },
      { name: "Toaster", url: `${baseUrl}/Toaster.png` },
      { name: "Hot Water", url: `${baseUrl}/Hot Water.png` },
      { name: "Wi-Fi", url: `${baseUrl}/wifi.png` },
      { name: "Dishwasher", url: `${baseUrl}/Dishwasher.png` },
      { name: "Alarm System", url: `${baseUrl}/alarm system.png` },
      { name: "Parking Space", url: `${baseUrl}/Parking Space.png` },
      { name: "Sofa", url: `${baseUrl}/sofa.png` },
      { name: "Coffee machine", url: `${baseUrl}/Coffee machine.png` },
      { name: "Bed", url: `${baseUrl}/Bed.png` },
      { name: "Dryer", url: `${baseUrl}/Dryer.png` },
      { name: "Terrace", url: `${baseUrl}/Terrace.png` },
  ];


  useEffect(() => {
 
    if (values?.features) {
      try {
        // Parse the features data if it's a string
        const parsedFeatures =
          typeof values.features === "string"
            ? JSON.parse(values.features)
            : values.features
   
        setFeatures(parsedFeatures)
      } catch (error) {
        console.error("Error parsing features:", error)
      }
    }
  }, [values?.features])
  const handleFeatureChange = event => {
    const { name, checked } = event.target
   
    setFeatures(prevFeatures => ({
      ...prevFeatures,
      [name]: checked,
    }))

    // Call the parent handler
    onInputChange({
      target: { name: "features", value: { ...features, [name]: checked } },
    })
  }

  const validate = () => {
    let validationErrors = {}
    if (!values.rooms) validationErrors.rooms = "This field is required."
    if (!values.size) validationErrors.size = "This field is required."

    if (!values.bathrooms)
      validationErrors.bathrooms = "This field is required."
    if (!values.bed_room) validationErrors.bed_room = "This field is required."

    if (!values.kitchen) validationErrors.kitchen = "This field is required."

    setErrors(validationErrors)
    return Object.keys(validationErrors).length === 0
  }

  const handleNext = () => {
    if (validate()) {
      setActiveTab("3")
    }
  }

  return (
    <div>
      <Row style={{ marginBottom: "40px" }}>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="rooms">
                Rooms <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
               type="text"
                name="rooms"
                id="rooms"
                onChange={onInputChange}
                value={values?.rooms || ""}
                readOnly={!residenceEdit}
              />
              {errors.rooms && (
                <FormText color="danger">{errors.rooms}</FormText>
              )}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="size">
                Size (sq m) <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
               type="text"
                name="size"
                id="size"
                onChange={onInputChange}
                value={values?.size || ""}
                readOnly={!residenceEdit}

              />
              {errors.size && <FormText color="danger">{errors.size}</FormText>}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="bathrooms">
                Bathrooms <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
               type="text"
                name="bathrooms"
                id="bathrooms"
                onChange={onInputChange}
                value={values?.bathrooms || ""}
                readOnly={!residenceEdit}

              />
              {errors.bathrooms && (
                <FormText color="danger">{errors.bathrooms}</FormText>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="bed_room">
                Beds <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
            type="text"
                name="bed_room"
                id="bed_room"
                onChange={onInputChange}
                value={values?.bed_room || ""}
                readOnly={!residenceEdit}

              />
              {errors.bed_room && (
                <FormText color="danger">{errors.bed_room}</FormText>
              )}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="kitchen">
                Kitchen <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="kitchen"
                id="kitchen"
                onChange={onInputChange}
                value={values?.kitchen || ""}
                readOnly={!residenceEdit}

              />
              {errors.kitchen && (
                <FormText color="danger">{errors.kitchen}</FormText>
              )}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="interior_exterior">Interior or Exterior</Label>
              <Input
                type="select"
                name="interior_exterior"
                id="interior_exterior"
                onChange={e => {
                  setSelectedType(e.target.value)
                  onInputChange(e) // Pass the select change to the parent
                }}
                value={selectedType || values?.interior_exterior}
                disabled={!residenceEdit}

              >
                <option value="">Select...</option>
                <option value="interior">Interior</option>
                <option value="exterior">Exterior</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Label for="">Additional Feature</Label>

          {featureList.map((feature, index) => (
    <Col md={4} key={index} className="d-flex align-items-center">
      <FormGroup check inline>
        <Label check className="form-check-label">
          <Input
            type="checkbox"
            name={feature.name}
            checked={features[feature.name] || false}
            onChange={(e) => handleFeatureChange(e, feature.url)}
            disabled={!residenceEdit}

          />
          <img
            src={feature.url}
            alt={feature.name}
            style={{ width: '24px', height: '24px', marginRight: '8px' }}
          />
          {feature.name}
        </Label>
      </FormGroup>
    </Col>
  ))}
        </Row>
      </Row>
      <Row style={{ marginBottom: "25px" }}>
        <Col md={6}>
          <Button color="secondary" onClick={() => setActiveTab("1")}>
            Back
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  )
}

FeatureApartments.propTypes = {
  values: PropTypes.shape({
    rooms: PropTypes.string,
    size: PropTypes.string,
    flatmates: PropTypes.string,
    bathrooms: PropTypes.string,
    bed_room: PropTypes.string,
    residence_features: PropTypes.string,
    living_room: PropTypes.string,
    dining_room: PropTypes.string,
    kitchen: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    interior_exterior: PropTypes.string,
    features: PropTypes.objectOf(PropTypes.bool), // Added validation for features
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

export default FeatureApartments
