import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"

import "./StyleTable.scss"
import Swal from "sweetalert2"
import { get, del,put } from "helpers/api_helper"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import { useNavigate } from "react-router-dom"
import { hasPermission } from "helpers/validateToken"
import ToggleButton from "components/togglebutton/ToggleButton"
import Loader from "components/loader/Loader"
import { useDispatch } from "react-redux";
import { toggleResidenceEdit } from "store/residence/actions"
import CustomPagination from "components/pagination/CustomPagination"
import { isAdmin } from "helpers/validateToken"
const Residence = () => {
  // const [filterType, setFilterType] = useState("")

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [limit, setLimit] = useState(10)
  const[ totalItems,setTotalItems] =useState(0)

  const [sortBY, setSortBY] = useState("residence_id")
  const [order, setOrder] = useState("DESC")
  const [isPermission, setIsPermission] = useState(false)
  const [selectedRows, setSelectedRows] = useState({})
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    type: "",
    value: "",
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [data, setData] = useState({
    columns: [
      {
        label: "ID",
        field: "idClick",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("residence_id"),
      },
    
      {
        label: "EMAIL",
        field: "user_email",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("email"),
      },
   
      {
        label: "INFORMATION",
        field: "information",
        sort: "asc",
        width: 100,
        onClick: () => handleSort("neighborhood"),
      },
      {
        label: "NEIGHBOURHOOD",
        field: "neighborhood",
        sort: "asc",
        width: 100,
        onClick: () => handleSort("neighborhood"),
      },
      {
        label: "PUBLISHED",
        field: "published",
        sort: "disabled",
        width: 100,
        onClick: () => handleSort("name"),
      },
   
    ],
    rows: [],
  })

  const filterData = () => {
    return data.rows.filter(row => {
      // const value = row[filter.type] || ""
      const value =
      filter.type === "city"
        ? row.address?.city || ""
        : row[filter.type] || "" // Check for nested properties
      switch (filter.type) {
        case "residence_id":
        case "name":
        case "residence_type":
        case "neighborhood":
        case "city":
          return value
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        default:
          return true
      }
    })
  }
  const handleCheckboxChange = residence_id => {
    setSelectedRows(prevSelected => ({
      ...prevSelected,
      [residence_id]: !prevSelected[residence_id],
    }))
  }
  const filteredData = {
    columns: [
      ...(isPermission
        ? [
            {
              label: "",
              field: "select",
              sort: "disabled",
              attributes: {
                "data-attributes": "select",
              },
            },
          ]
        : []),
      ...data.columns,
    ],
    rows: filterData().map(row => ({
      ...row,
      select: isPermission ? (
        <Input
          type="checkbox"
          checked={!!selectedRows[row.residence_id]}
          onChange={() => handleCheckboxChange(row.residence_id)}
        />
      ) : null,
    })),
  }

  const fetchResidence = async (
    page,
    limit,
    sortBY,
    order,
    filterType,
    filterValue
  ) => {
    setLoading(true)
    try {
      const token = authHeader() // Fetch the authorization token
      const user = JSON.parse(localStorage.getItem("authUser")); // Retrieve the logged-in user's information
      const userId = user.user?.id


    

  
  
      const config = {
        headers: {
          Authorization: token,
        },
      }

      // Start building the query parameters
      const params = new URLSearchParams({
        page,
        limit,
        sortBy: sortBY,
        order,
      })

      // Add filter parameters based on the filterType and filterValue
      if (filterType && filterValue) {
        params.append(filterType, filterValue)
      }
      if (!isAdmin) {
        params.append("owner_id", userId);
      }
      const res = await get(
        `/allresidenceswithfilter?${params.toString()}`,
        config
      )

      // Log each item to the console
      const mappedRows = res.data?.map(item => {
       
        return {
          ...item,

          idClick: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/residence/edit/${item.residence_id}`)
    dispatch(toggleResidenceEdit(false))

              }}
              dangerouslySetInnerHTML={{
                __html: `
                  <div style="color: #45c4ad;">${item.residence_id}</div>
                `,
              }}
            />
          ),
          information: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/residence/edit/${item.residence_id}`)

    dispatch(toggleResidenceEdit(false))

              }}
              dangerouslySetInnerHTML={{
                __html: `
                  <div style="color: #45c4ad;">${item.name} - ${
                  item.owner?.user_email
                }</div>
                  <div>Type: ${item.residence_type}</div>
                  <div>Booking Status: 
                  <span style="color: ${
                    item.booking_status === "Available" ? "green" : "red"
                  };">
                  ${item.booking_status !== null ? item.booking_status:""}
                  </span>
                </div>
        
                `,
              
              }}
            />
          ),
          user_email: item.owner?.user_email,
          published: (
            <div>
              <ToggleButton
                isActive={item.active === true}
                onToggle={() =>
                  handleToggle(item.residence_id, item.active === true)
                }
              />
            </div>
          ),
        }
      })

      setTotalPages(res?.pagination?.totalPages || 1)
      setTotalItems(res?.pagination?.totalItems||"")

      setData({
        columns: data.columns, // Keep existing columns
        rows: mappedRows || [], // Set mapped rows
      })
    } catch (error) {
      console.log("error", error)
    } finally {
      setLoading(false)
    }
  }
  const handleToggle = async (residencId, currentStatus) => {
    const token = authHeader() // Fetch the authorization token

    const config = {
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await put(`/residences/${residencId}`, {
        active: !currentStatus // Toggle publish status
      },config);

      if (response) {
        // Update your local state with the new data
        fetchResidence(currentPage, limit, sortBY, order)

        // Optionally, re-fetch the rooms to get updated data
      }
    } catch (error) {
      console.error("Error updating residence:", error);
    }
  };
  const handleSort = field => {
    // const direction = sortBY === field && order === "ASC" ? "DESC" : "ASC";
    setSortBY(field)
    setOrder(prevOrder => (prevOrder === "ASC" ? "DESC" : "ASC"))
    fetchResidence(currentPage, limit, field, order, filter.type, filter.value)
  }
  const deleteResidence = async () => {
    const token = authHeader() // Fetch the authorization token
    const idsToDelete = Object.keys(selectedRows).filter(id => selectedRows[id])

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            Authorization: token,
          },
        }

        try {
          await Promise.all(
            idsToDelete.map(id => del(`/residences/${id}`, config))
          )

          Swal.fire({
            title: "Deleted!",
            text: "Your Residence has been deleted.",
            icon: "success",
            timer: 3000, // 3 seconds timer
          })
          setSelectedRows({})
          setFilter({type:"",value:""})

          fetchResidence(currentPage, limit, sortBY, order) // Refresh the data
        } catch (error) {
       
          Swal.fire({
            title: "Error!",
            text: "An error occurred while deleting the Residence.",
            icon: "error",
          })
        }
      }
    })
  }
  const selectedCount = Object.keys(selectedRows).filter(id => selectedRows[id]).length;
 
  useEffect(() => {
    // Get permissions and user role from localStorage
    const obj = JSON.parse(localStorage.getItem("permission"))
    const permissions = JSON.parse(obj?.permission)


    // Check if the user has the permission to access 'residence'
    const hasPerm = hasPermission(
      permissions,
      "Residence",
      ["Read-only", "Read-write"],
      isAdmin
    )

    // If the user is Admin, they should have full access
    if (isAdmin) {
      setIsPermission(true) // Admin has access to everything
    } else if (!hasPerm) {
      navigate("/pages-404") // Redirect if no permission
      setIsPermission(false) // Set permission state to false
    } else {
      // Handle other permissions like 'Read-only' and 'Read-write'
      const role = permissions["Residence"]
      if (role === "Read-only") {
        setIsPermission(false) // Limited access
      } else if (role === "Read-write") {
        setIsPermission(true) // Full access
      } else {
        setIsPermission(false) // Default case
      }
    }
  }, [])
  useEffect(() => {
    fetchResidence(currentPage, limit, sortBY, order,    filter.type,
      filter.value)
  }, [currentPage, limit, sortBY, order,filter.value.length])

  const handleTableChange = newPage => {
    setCurrentPage(newPage)
  }

  document.title = "Residence | SHS Admin"

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Residence"
            breadcrumbItem={"List"}
            link="residence" 
            
          />

          <Row>
            <Col className="col-12">
              <Card>
                <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                >
                  {Object.keys(selectedRows).some(id => selectedRows[id]) ? (
                    <Button color="danger" onClick={deleteResidence}>
                      Delete Selected {selectedCount}
                    </Button>
                  ) : (
                    isPermission && (
                      <Button
                        color="primary"
                        onClick={(()=>{
                          navigate(`/residence/add`)
                          localStorage.removeItem("residenceId");
    dispatch(toggleResidenceEdit(true))
                           
                        })}
                      >
                        {" "}
                        Add Residence
                      </Button>
                    )
                  )}
                </Col>
            <CardBody>
                  <Row className="mb-3">
                    <Col md={2}>
                      <FormGroup>
                        <Label for="filterType">Filter By</Label>
                        <Input
                          type="select"
                          id="filterType"
                          value={filter.type}
                          onChange={e =>
                            setFilter({ type: e.target.value, value: "" })
                          }
                        >
                          <option value="">Select</option>
                          <option value="residence_id">By ID</option>
                          <option value="name">By Name</option>
                          {/* <option value="city">By City</option> */}
                          <option value="residence_type">
                            By Residence type
                          </option>
                          <option value="neighborhood">By Neighborhood</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    {filter.type &&(
                      <Col md={2}>
                        <FormGroup>
                          <Label for="filterValue">Filter Value</Label>
                          <Input
                            type="text"
                            id="filterValue"
                            placeholder={`Enter ${filter.type}`}
                            value={filter.value}
                            onChange={e =>
                              setFilter({ ...filter, value: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  {   loading ? (<Loader/>)  :  
              (    <><MDBDataTable
                      responsive
                      striped
                      bordered
                      searching={false}
                      data={filteredData}
                      paging={false} />
                      
                      
                    { totalItems>limit&& <div className="d-flex justify-content-between align-items-center mt-3">
                        <FormGroup>
                          <Label for="limitSelect">Items per Page</Label>
                          <Input
                            type="select"
                            id="limitSelect"
                            value={limit}
                            onChange={e => {
                              setLimit(parseInt(e.target.value))
                              setCurrentPage(1) // Reset to the first page when limit changes
                            } }
                          >
                      
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </Input>
                        </FormGroup>
                        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleTableChange ={handleTableChange}
          />
                      </div>}</> )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Residence
