import Home from 'Home';

import React,{} from "react"









const App = () => {


  return (
    <React.Fragment>
     <Home/>
    </React.Fragment>
  );
};





export default App;
