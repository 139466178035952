import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumb = (props) => {
  return (
    <Row className="align-items-center">
      <Col sm={6}>
        <div className="page-title-box">
          <h4 className="font-size-18" style={{ textTransform: "capitalize" }}>{props.breadcrumbItem}</h4>
          <ol className="breadcrumb mb-0">
            {props.maintitle && (
              <BreadcrumbItem>
                <Link to="/#">{props.maintitle}</Link>
              </BreadcrumbItem>
            )}
            {props.link && (
              <BreadcrumbItem>
                <Link to={`/${props.link}`}>{props.title}</Link>
              </BreadcrumbItem>
            )}
            <BreadcrumbItem active>
              {props.breadcrumbItem}
            </BreadcrumbItem>
          </ol>
        </div>
      </Col>
    </Row>
  );
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  maintitle: PropTypes.string
};

export default Breadcrumb;


