import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { isAdmin } from "helpers/validateToken"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"

import Swal from "sweetalert2"
import { get, del } from "helpers/api_helper"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import { useNavigate } from "react-router-dom"
import { hasPermission } from "helpers/validateToken"
import Loader from "components/loader/Loader"
import moment from "moment"
import CustomPagination from "components/pagination/CustomPagination"


const Bookings = () => {

  const [filter, setFilter] = useState({
    type: "",
    value: "",
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [selectedRows, setSelectedRows] = useState({})
  const [limit, setLimit] = useState(10)
  const[ totalItems,setTotalItems] =useState(0)
  
  const [sortBY, setSortBY] = useState("id")
  const [order, setOrder] = useState("DESC")
  const [loading, setLoading] = useState(true)

  const [isPermission, setIsPermission] = useState(false)
  const navigate = useNavigate()
  const [data, setData] = useState({
    columns: [
      {
        label: "BOOKING ID",
        field: "bookingId",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("id"),
      },
  


      {
        label: "TIME SLOT",
        field: "timeSlot",
        sort: "asc",
        width: 200,
        onClick: () => handleSort("timeSlot"),

      },

      {
        label: "CUSTOMER NAME",
        field: "customerName",
        sort: "asc",
        width: 200,
        onClick: () => handleSort("customerName"),
        
      },
          {
        label: "CREATED AT",
        field: "created_at",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("created_at"),
      },
      {
        label: "STATUS",
        field: "status",
        sort: "asc",
        width: 100,
        onClick: () => handleSort("statusShow"),

      },
    ],
    rows: [],
  })

  // const filterData = () => {

  //   return data.rows.filter(row => {
  //     const value = row[filter.type] || "";
  //     return value.toString().toLowerCase().includes(filter.value.toString().toLowerCase());
  //   });
  // }
  const filterData = () => {

  
    return data.rows.filter(row => {
      // Check if the value is a React element (div) with dangerouslySetInnerHTML
      const statusElement = row[filter.type];
  
      // If it's a React element (div), extract the text from the inner HTML
      let value = "";
      if (statusElement && statusElement.props && statusElement.props.dangerouslySetInnerHTML) {
        // Extract the content inside the dangerouslySetInnerHTML
        const innerHtml = statusElement.props.dangerouslySetInnerHTML.__html;
        const match = innerHtml && innerHtml.match(/>(.*?)<\/div>/);
        if (match) {
          value = match[1];  // This is the status text inside the div
        }
      } else {
        // If it's not a React element, just use the raw value (fallback)
        value = statusElement || "";
      }
  

  
      // Check if the value includes the filter value (case insensitive)
      return value.toString().toLowerCase().includes(filter.value.toString().toLowerCase());
    });
  };
  

  const handleCheckboxChange = id => {
    setSelectedRows(prevSelected => ({
      ...prevSelected,
      [id]: !prevSelected[id],
    }))
  }

  const filteredData = {
    columns: [
      ...(isPermission
        ? [
            {
              label: "",
              field: "select",
              sort: "disabled",
            },
          ]
        : []),
      ...data.columns,
    ],
    rows: filterData().map(row => {
    
      return {
        ...row,
        select: isPermission ? (
          <Input
            type="checkbox"
            checked={!!selectedRows[row.id]}
            onChange={() => handleCheckboxChange(row.id)}
          />
        ) : null,
      }
    }),
  }


  const fetchBookings = async (
    page,
    limit,
    sortBY,
    order,
    filterType,
    filterValue
  ) => {
    setLoading(true)
    try {
      const token = authHeader() // Fetch the authorization token
      const user = JSON.parse(localStorage.getItem("authUser")); // Retrieve the logged-in user's information
      const userId = user.user?.id


      const config = {
        headers: {
          Authorization: token,
        },
      }

      // Start building the query parameters
      const params = new URLSearchParams({
        page,
        limit,
        sortBy: sortBY,
        order,
      })

      // Add filter parameters based on the filterType and filterValue
      // if (filterType && filterValue) {
      //   params.append(filterType, filterValue)
      // }
      if (filterType && filterValue) {
        // If filterType is "id", change it to "order_id"
        const actualFilterType = filterType === "id" ? "order_id" : filterType;
        params.append(actualFilterType, filterValue);
      }
      // check to ge the owner specific data //
      if (!isAdmin) {
        params.append("owner_id", userId);
      }
      const response = await get(
        `/bookingswithfilters?${params.toString()}`,
        config
      )
      // Log each item to the console
      const res = response.data


      // Check if the response contains valid data
  
      const mappedRows = res.map((item, index) => {
        const detail = item.detail; // Parse the detail string
   
        return {
          ...item,
          bookingId: (
            <div
              key={`booking-${item.id}-${index}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/booking/${item.id}`, { state: { orederId: item.detail.
                  order_id } });
              }}
              dangerouslySetInnerHTML={{
                __html: `
                    <div style="color: #45c4ad;">${item.detail.order_id} </div>
                  `,
              }}
            />
          ),
          customerName: (
            <div
              key={`customer-${item.id}-${index}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/booking/${item.id}`, { state: { orederId: item.detail.
                  order_id } }               );
              }}
              dangerouslySetInnerHTML={{
                __html: `
                    <div style="color: #45c4ad;">
                      ${detail?.billing.first_name} ${detail?.billing.last_name}
                    </div>
                  `,
              }}
            />
          ), 
          order_id:item.detail.order_id,
          // Similarly, ensure all other React elements have a unique key.
          timeSlot: detail?.items[0].Booking_slot || "", 
          status: (
            <div
              key={`status-${item.id}-${index}`}
              onClick={() => {
                navigate(`/booking/${item.id}`, { state: { orederId: item.detail.
                  order_id } });
              }}
              style={{ cursor: "pointer" }}
              dangerouslySetInnerHTML={{
                __html: `
                    <div style="color: ${
                      item.status === "completed" || item.status === "expired"
                        ? "green"
                        : item.status === "pending"
                        ? "#e3b51e"
                        : item?.status === null || item.status ===
                        "on-hold"  ? "#45c4ad" : "red"
                    };"> ${item?.status === null || item.status === "on-hold" ? "Request" : item?.status === "expired" ? "completed":item.status}</div>
                  `,
              }}
            />
          ),
          created_at: moment(item.created_at ).format("LL")|| "",
          // created_at: item.createdAt
          // ? moment(item.createdAt).format("LL")
          // : "Date not available"
        
        };
      });
      
     
        setTotalPages(response?.pages || 1)
      setTotalItems(response?.total||"")

        setData({
          columns: data.columns,
          rows: mappedRows || [],
        })
      
    } catch (error) {
      console.log("error", error)
    } finally {
      setLoading(false)
    }
  }

  const deleteBookings = async () => {
    const token = authHeader()
    const idsToDelete = Object.keys(selectedRows).filter(id => selectedRows[id])

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            Authorization: token,
          },
        }

        try {
          const res = await Promise.all(
            idsToDelete.map(id => del(`/bookings/${id}`, config))
          )

          Swal.fire({
            title: "Deleted!",
            text: res[0]?.message,
            icon: "success",
            timer: 3000,
          })
          setSelectedRows({})
          fetchBookings(currentPage, limit, sortBY, order, filter.type, filter.value)
        } catch (error) {
 
          Swal.fire({
            title: "Error!",
            text: "An error occurred while deleting the booking.",
            icon: "error",
          })
        }
      }
    })
  }

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("permission"))
    const permissions = JSON.parse(obj?.permission)

    const hasPerm = hasPermission(
      permissions,
      "Bookings",
      ["Read-only", "Read-write"],
      isAdmin
    )

    if (isAdmin) {
      setIsPermission(true)
    } else if (!hasPerm) {
      navigate("/pages-404")
      setIsPermission(false)
    } else {
      const role = permissions["Bookings"]
      setIsPermission(role === "Read-write")
    }
  }, [])
  const handleSort = field => {
    // const direction = sortBY === field && order === "ASC" ? "DESC" : "ASC";
    setSortBY(field)
    setOrder(prevOrder => (prevOrder === "ASC" ? "DESC" : "ASC"))
    fetchBookings(currentPage, limit, field, order, filter.type, filter.value)
  }
  useEffect(() => {
    fetchBookings(currentPage, limit, sortBY, order, filter.type, filter.value);
  }, [currentPage, limit, sortBY, order, filter.value.length]);
  

  const handleTableChange = newPage => {
    setCurrentPage(newPage)
  }
  const selectedCount = Object.keys(selectedRows)?.filter(id => selectedRows[id]).length;
  document.title = "Bookings | SHS Admin"

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Bookings"
            breadcrumbItem={"List"}
            link="bookings"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                >
                  {Object.keys(selectedRows).some(id => selectedRows[id]) ? (
                    <Button color="danger" onClick={deleteBookings}>
                      Delete Selected {selectedCount}
                    </Button>
                  ) : ""}
                </Col>
             
                  <CardBody>
                    <Row className="mb-3">
                      <Col md={2}>
                        <FormGroup>
                          <Label for="filterType">Filter By</Label>
                          <Input
                            type="select"
                            id="filterType"
                            value={filter.type}
                          onChange={e =>
                            setFilter({ type: e.target.value, value: "" })
                          }
                          >
                            <option value="">Select</option>
                            <option value="order_id">By Booking ID</option>
                            {/* <option value="customerName">
                              By Customer Name
                            </option> */}
                            {/* <option value="order_id">By Order ID</option> */}
                            <option value="timeSlot">By Time Slot</option>
                            <option value="created_at">By Created date</option>

                            <option value="status">By Status</option>
                            {/* <option value="bookingType">By Booking Type</option> */}
                          </Input>
                        </FormGroup>
                      </Col>
                      {filter.type && (
                      <Col md={2}>
                        <FormGroup>
                          <Label for="filterValue">Filter Value</Label>
                          <Input
                            type="text"
                            id="filterValue"
                            placeholder={`Enter ${filter.type}`}
                            value={filter.value}
                            onChange={e =>
                              setFilter({ ...filter, value: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                    )}
                    </Row>
                    {loading ? (
                  <Loader />
                ) :
           (     <><MDBDataTable
                      responsive
                      striped
                      bordered
                      searching={false}
                      data={filteredData}
                      paging={false} />
                      
                      
          {   totalItems > limit &&         <div className="d-flex justify-content-between align-items-center mt-3">
                        <FormGroup>
                          <Label for="limitSelect">Items per Page</Label>
                          <Input
                            type="select"
                            id="limitSelect"
                            value={limit}
                            onChange={e => {
                              setLimit(parseInt(e.target.value))
                              setCurrentPage(1)
                            } }
                          >
                        
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </Input>
                        </FormGroup>
                        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleTableChange ={handleTableChange}
          />
                      </div>}</>)   }
                  </CardBody>
                 
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Bookings
