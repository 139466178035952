import React from "react"; // Ensure React is imported if necessary
import PropTypes from "prop-types"; // Import PropTypes for validation
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";

const CustomPagination = ({ currentPage, totalPages, handleTableChange }) => {
  const getPageNumbers = () => {
    const pageNumbers = [];

    // Always show the first page
    if (totalPages > 1) pageNumbers.push(1);

    // Add ellipsis if the current page is beyond the start
    if (currentPage > 3) pageNumbers.push("...");

    // Pages around the current page
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
      pageNumbers.push(i);
    }

    // Add ellipsis if the current page is not near the end
    if (currentPage < totalPages - 2) pageNumbers.push("...");

    // Always show the last page
    if (totalPages > 1) pageNumbers.push(totalPages);

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <MDBPagination className="mt-3">
      <MDBPaginationItem disabled={currentPage === 1}>
        <MDBPaginationLink
          onClick={() => handleTableChange(currentPage - 1)}
          tabIndex="-1"
          aria-disabled={currentPage === 1}
        >
          Previous
        </MDBPaginationLink>
      </MDBPaginationItem>

      {pageNumbers.map((page, index) =>
        page === "..." ? (
          <MDBPaginationItem key={index} disabled>
            <MDBPaginationLink>...</MDBPaginationLink>
          </MDBPaginationItem>
        ) : (
          <MDBPaginationItem key={page} active={currentPage === page}>
            <MDBPaginationLink
              onClick={() => handleTableChange(page)}
              style={{
                marginLeft: "3px",
                marginRight: "3px",
              }}
            >
              {page}
            </MDBPaginationLink>
          </MDBPaginationItem>
        )
      )}

      <MDBPaginationItem disabled={currentPage === totalPages}>
        <MDBPaginationLink
          onClick={() => handleTableChange(currentPage + 1)}
          aria-disabled={currentPage === totalPages}
        >
          Next
        </MDBPaginationLink>
      </MDBPaginationItem>
    </MDBPagination>
  );
};

// Define PropTypes for validation
CustomPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handleTableChange: PropTypes.func.isRequired,
};

export default CustomPagination;
