import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { get } from "helpers/api_helper";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import { isAdmin } from "helpers/validateToken";
import {  useSelector } from 'react-redux';
const General = ({ values, onInputChange, onAddressChange, setActiveTab }) => {
  // State for validation errors
  const [errors, setErrors] = useState({});
  const [allUser, setAllUser] = useState();
  const isApartmentEdit = useSelector((state) => state.apartment.isEditing);

  const [allNeighborhood, setAllNeighborhood] = useState()

  const navigate = useNavigate();
  // Validation function
  const validate = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "address.city",
      "address.street",
      "zipcode",
      "price",
      "deposit",
        "neighborhood",
      "apartment_type",
      "active",
      "booking_status"
 
    ];
  
    requiredFields.forEach((field) => {
      const value = field
        .split(".")
        .reduce((acc, part) => acc && acc[part], values);
        const zipCodePattern = /^\d{5}$/;
        if (values.zipcode && !zipCodePattern.test(values.zipcode)) {
          newErrors.zipcode = "Please enter the correct 5 digit number";
        }
  
      // Check for required fields
      if (!value || (Array.isArray(value) && value.length === 0)) {
        newErrors[field] = "This field is required";
      }
    });
  
    // Check owner_id specifically if isAdmin is true
    if (isAdmin) {
      if (!values.owner_id) {
        newErrors.owner_id = "This field is required";
      }
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  
 

  const user = JSON.parse(localStorage.getItem("authUser")); // Retrieve the logged-in user's information
  const userId = user.user?.id;
  const userName = `${user.user?.first_name} ${user.user?.last_name}`




  // Handle Next button click
  const handleNext = () => {
    if (validate()) {
      setActiveTab("2");
    }
  };
  const getAllUser = async () => {
    const token = authHeader();
    const config = {
      headers: {
        Authorization: token,
      },
    };

    try {
      const res = await get(`/usersall`, config);

      setAllUser(res.data);
    } catch (error) {
      console.log("an error occured", error);
    }
  };
  const getNeighbourhood = async () => {
    const token = authHeader();
    const config = {
      headers: {
        Authorization: token,
      },
    };

    try {
      const res = await get(`/neighborhood`, config);

      setAllNeighborhood(res.data);
    } catch (error) {
      console.log("an error occured", error);
    }
  };

  // Filter and format the slot options based on the current date

  useEffect(() => {
    // getting  for the all user and  render in the field of the  owner//
    getAllUser();
   getNeighbourhood()
  }, []);

  return (
    <div>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="name">
              Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="name"
              type="text"
              id="name"
              placeholder="Enter Name"
              onChange={onInputChange}
              value={values?.name || ""}
              invalid={!!errors.name}
              readOnly={!isApartmentEdit}
            />
            {errors.name && (
              <FormFeedback style={{ color: "red" }}>
                {errors.name}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
  <FormGroup>
    <Label for="owner_id">
      Owner Name <span style={{ color: "red" }}>*</span>
    </Label>
    <Input
      name="owner_id"
      type="select"
      id="owner_id"
      placeholder="Enter owner id"
      onChange={onInputChange}
      value={isAdmin ? values?.owner_id || "" : userId}
      invalid={!!errors.owner_id}
      disabled={!isApartmentEdit}


  
    >
      {/* Display the current user's name as a disabled option if not admin */}
      {!isAdmin && userId && (
        <option value={userId} >
          {userName}
        </option>
      )}

      {/* If admin, list all users */}
      {isAdmin && (
        <>
          {!values?.role && <option value="">Select Name</option>}
          {allUser?.map((item) => (
            <option key={item?.id} value={item?.id}>
              {item?.first_name} {item?.last_name}
            </option>
          ))}
        </>
      )}
    </Input>
    {errors.owner_id && (
      <FormFeedback style={{ color: "red" }}>
        {errors.owner_id}
      </FormFeedback>
    )}
  </FormGroup>
</Col>

<Col md={4}>
          <FormGroup>
            <Label for="apartment_type">
              {" "}
              Apartment Type <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="apartment_type"
              type="select"
              id="apartment_type"
              placeholder="Enter Residence Type"
              onChange={onInputChange}
              value={values?.apartment_type || ""}
              invalid={!!errors.apartment_type}
              disabled ={!isApartmentEdit}
            >
              <option value="">Select</option>
              <option value="Entire">Entire</option>
              <option value="Shared">Shared</option>
            </Input>
            {errors.apartment_type && (
              <FormFeedback style={{ color: "red" }}>
                {errors.apartment_type}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
   
      </Row>
      <Row>
      <Col md={4}>
          <FormGroup>
            <Label for="addressCity">
              City <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="address.city"
              type="text"
              id="addressCity"
              placeholder="Enter City"
              value={values?.address?.city || ""}
              onChange={onAddressChange}
              invalid={!!errors["address.city"]}
              readOnly={!isApartmentEdit}

            />
            {errors["address.city"] && (
              <FormFeedback style={{ color: "red" }}>
                {errors["address.city"]}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="addressStreet">
              Street <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="address.street"
              type="text"
              id="addressStreet"
              placeholder="Enter Street"
              value={values?.address?.street || ""}
              onChange={onAddressChange}
              invalid={!!errors["address.street"]}
              readOnly={!isApartmentEdit}

            />
            {errors["address.street"] && (
              <FormFeedback style={{ color: "red" }}>
                {errors["address.street"]}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="zipcode">
              Zipcode <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="zipcode"
              type="text"
              id="zipcode"
              placeholder="Enter Zipcode"
              onChange={onInputChange}
              value={values?.zipcode || ""}
              invalid={!!errors.zipcode}
              readOnly={!isApartmentEdit}

            />
            {errors.zipcode && (
              <FormFeedback style={{ color: "red" }}>
                {errors.zipcode}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>

      </Row>
      <Row>
      <Col md={4}>
          <FormGroup>
            <Label for="price">
              Price <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="price"
              type="text"
              id="price"
              placeholder="Enter Price"
              onChange={onInputChange}
              value={values?.price || ""}
              invalid={!!errors.price}
              readOnly={!isApartmentEdit}

            />
            {errors.price && (
              <FormFeedback style={{ color: "red" }}>
                {errors.price}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="deposit">
              Deposit <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="deposit"
              type="text"
              id="deposit"
              placeholder="Enter Deposit"
              onChange={onInputChange}
              value={values?.deposit || ""}
              invalid={!!errors.deposit}
              readOnly={!isApartmentEdit}

            />
            {errors.deposit && (
              <FormFeedback style={{ color: "red" }}>
                {errors.deposit}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
   
        <Col md={4}>
          <FormGroup>
            <Label for="active">
              Published <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="select"
              name="active"
              id="active"
              onChange={onInputChange}
              value={values?.active !== undefined ? String(values.active) : ""}
              invalid={!!errors.active}
              disabled={!isApartmentEdit}

            >
              <option value="">Select published type</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </Input>
            {errors.active && (
              <FormFeedback style={{ color: "red" }}>
                {errors.active}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>
    <Row>
    <Col md={4}>
          <FormGroup>
            <Label for="neighborhood">
              Neighbourhood <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="neighborhood"
              type="select"
              id="neighborhood"
              placeholder="Enter neighborhood"
              onChange={onInputChange}
              value={values.neighborhood || ""} // Initialize with an empty array for multiple values
              invalid={!!errors.neighborhood}
              disabled={!isApartmentEdit}


            >
              <option value="">Select</option>
              {allNeighborhood?.map((item) => {
                return (
 
                    <option key={item?.id} value={item?.neighborhood}>
                      {item?.neighborhood}
                    </option>
             
                );
              })}
            </Input>
            {errors.neighborhood && (
              <FormFeedback style={{ color: "red" }}>
                {errors.neighborhood}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
    <Col md={4}>
          <FormGroup>
            <Label for="apartment_type">
              {" "}
              Availability <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="booking_status"
              type="select"
              id="booking_status"
              placeholder="Enter Residence Type"
              onChange={onInputChange}
              value={values?.booking_status || ""}
              invalid={!!errors.booking_status}
              disabled ={!isApartmentEdit}
            >
              <option value="">Select</option>
              <option value="Available">Available</option>
              <option value="Unavailable">Unavailable</option>
            </Input>
            {errors.booking_status && (
              <FormFeedback style={{ color: "red" }}>
                {errors.booking_status}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
    </Row>

      <Row>
        <Col md={6}>
          <Button color="secondary" onClick={() => navigate("/apartments")}>
            Back
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

// Define PropTypes
General.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default General;
