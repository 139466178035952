import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, Col, Row, Button, } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import { get, post,put } from "helpers/api_helper"

import Swal from "sweetalert2"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import Loader from "components/loader/Loader"
import { useLocation } from 'react-router-dom';



const BookingsDetail = () => {
  const { id } = useParams()
  const [bookingData, setBookingData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [noteData,setNoteData] = useState()
  // const [bookingStatus, setBookingStatus] = useState("");
  const navigate = useNavigate()
  const location = useLocation();
  const { orederId } = location.state || {};
 
  const fetchBookingById = async () => {
    try {
      const token = authHeader()
      const config = { headers: { Authorization: token } }
      const res = await get(`bookings/${id}`, config)
      // const res = await axios.get(`https://api.shs-app.es/beyondapi/api/bookings/109`, config)

      if (res.data) {
        setBookingData(res.data)
      } else {
        Swal.fire("Error", "Unable to fetch booking details", "error")
      }
    } catch (error) {
      Swal.fire(
        "Error",
        error.message || "Failed to fetch booking details",
        "error"
      )
    } finally {
      setLoading(false)
    }
  }

  const updateStatus = async status => {
    const token = authHeader()
    const config = { headers: { Authorization: token } }

    try {
      const response = await post(
        `/bookingStatus/${bookingData.detail.order_id}`,
        { status },
        config
      )

      Swal.fire({
        title: "Status Updated!",
        text: response.message,
        icon: "success",
        timer: 3000,
      }).then(() => {
        navigate("/bookings")
      })
    } catch (error) {
      console.error("Error updating status:", error)
      Swal.fire(
        "Error",
        error.response
          ? error.response.data.message
          : "Failed to update booking status",
        "error"
      )
    }
  }
  const  rejectBooking = async order_id => {
  
    const token = authHeader()
    const config = { headers: { Authorization: token } }

    try {
      const response = await put(
        `/bookings/${id}`,
        { order_id },
        config
      )

      Swal.fire({
        title: "Status Updated!",
        text: response.message,
        icon: "success",
        timer: 3000,
      }).then(() => {
        navigate("/bookings")
      })
    } catch (error) {
      console.error("Error updating status:", error)
      Swal.fire(
        "Error",
        error.response
          ? error.response.data.message
          : "Failed to update booking status",
        "error"
      )
    }
  }

  const fetchNotes = async()=>{
    try{
     
      const token = authHeader()
      const config = { headers: { Authorization: token } }
      // const config = { headers: { Authorization:  "brear eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTksImlhdCI6MTcyOTAwNzYxMX0.dYSRPcikspSWorDwp3WqHK7W7bfxc1HnFOAR5wdwwIc" } }

      // const res = await get(`bookings/${id}`, config)
      const res = await get(`/getbookingnotes/${orederId}`, config)
     
      if(res){
        setNoteData(res.data)
      }

    }catch (err){
      console.log("error",err)

    }

  }
 
  useEffect(() => {
    fetchBookingById()
    fetchNotes()


  }, [id])

  if (loading) {
    return <Loader />
  }

  if (!bookingData) {
    return <p>No booking details available.</p>
  }

  const { order_id, billing,  items,  } =
    bookingData.detail || {}
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    const formatLabel = (key) => {
      return key.replace(/_/g, ' ').split(' ').map(word => capitalizeFirstLetter(word)).join(' ');
    };
    const getStripePaymentDetails = (stripeData) => {
      const keysToShow = [
        '_stripe_customer_id',
        '_stripe_intent_id',
        '_stripe_source_id',
        '_stripe_upe_payment_type',
      ];
    
      return stripeData
        .filter(item => keysToShow.includes(item.key))
        .map(item => ({
          label:formatLabel(item.key),
          value: item.value,
        }));
    };
    const paymentDetails = getStripePaymentDetails(bookingData?.detail.stripe?.address_1 || []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Bookings"
            link="bookings"
            breadcrumbItem="Booking Details"
          />
          <Card className="p-3">
            <Row>
              <Col md={6}></Col>
              {(bookingData.status === null ||
                bookingData.status === "on-hold") && (
                <Col md={6} className="text-end">
                  <Button
                    color="success"
                    onClick={() => updateStatus("completed")}
                  >
                    Accept Booking
                  </Button>
                  <Button
                    color="danger"
                    className="ms-2"
                    onClick={() => rejectBooking(bookingData.detail.order_id)}
                  >
                    Reject Booking
                  </Button>
                </Col>
              )}
            </Row>
            <h4>Booking #{order_id}</h4>
            <Row>
              <Col md={6}>
                <h5>Billing Details</h5>
                <p>
                  {billing?.first_name} {billing?.last_name}
                </p>
                <p>Email: {billing?.email}</p>
                <p>Phone: {billing?.phone}</p>
                <p>
                  Address: {billing?.address_1}, {billing?.city}, {billing?.state},{" "}
                  {billing?.postcode}
                </p>

              </Col>
              <Col md={6}>
    <h5>Payment Details</h5>
    {paymentDetails?.map((detail, index) => (
      <p key={index}>
        {detail.label}: {detail.value}
      </p>
    ))}
  </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
              <h5>Order Details</h5>
                <p>
                Slot:{" "} {""}
                   {bookingData.detail.items[0].Booking_slot}
                </p>
        
                <p>
                  Order Date:{" "} {""}
                  {new Date(bookingData.created_at).toLocaleString()}
                </p>
               
                <p>
                  Order Status:{" "} {""}
                  <span
                    style={{
                      color:
                        bookingData.status === "completed" || bookingData.status === "expired"
                          ? "green"
                          : bookingData.status ===null || bookingData.status === "on-hold"
                          ? "#e3b51e"
                          : "red",
                    }}
                  >
                    {bookingData?.status === null || bookingData.status === "on-hold"?"Pending For Approval":bookingData.status==="expired"?"completed":bookingData?.status}
                  </span>
                </p>
              </Col>
            </Row>
            <h5 className="mt-4">Items</h5>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>ID</th>
               
                    <th>Subtotal</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.product_name}</td>
                      <td>{item.product_id}</td>
                   
                      <td>{item.subtotal}</td>
                      <td>{item.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h5>Notes:</h5>
            {noteData?.map((item,index)=>{
            
              return(

                <Row key={index}>
              
                  <Col md={10}>
                  <p>
                     {index+1}.{item?.note}
                  </p>
                  </Col>
                </Row>
              )
            })

            }
                     {/* <Row>
  {noteData.map((item, index) => (
    <Col key={index} md={6}> 
      <p>{item?.note}</p>
    </Col>
  ))}
</Row> */}
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BookingsDetail
