
import React, { } from "react"

import { Row, Col, FormGroup, Label,Button } from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for React Quill
import PropTypes from "prop-types";
import "./Style.scss"

import {  useParams } from "react-router-dom"
import {  useSelector } from 'react-redux';


const EditerComponent = ({ onChangeHandlerFn,values, onInputChange,setActiveTab }) => {
  // Function to handle editor change
 
  const { id } = useParams()

  const residenceEdit = useSelector((state) => state.residence.isEditing);

  const handleEditorChange = (value, name) => {
    onInputChange({ target: { name, value } });
  };

  // Formats for the editor
  const formats = [
    "font", "size",
    "bold", "italic", "underline", "strike",
    "color", "background",
    "script",
    "header", "blockquote", "code-block",
    "indent", "list",
    "direction", "align",
    "link", "image", "video", "formula",
  ];

  // Modules for the editor
  const modules = {
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      [{ 'header': '1'}, {'header': '2'}, { 'script': 'sub'}, { 'script': 'super' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
      ['blockquote', 'code-block'],
      [{ 'direction': 'rtl' }, { 'align': [] }],
      ['link', 'image', 'video', 'formula'],
      ['clean']
    ],
  };


  return (
<>
<Row>
<Col md={12}>
        <FormGroup>
          <Label for="description">Description</Label>
          <ReactQuill
            value={values.description}
            onChange={(value) => handleEditorChange(value, "description")}
            placeholder="Enter Description"
            formats={formats}
            modules={modules}
            readOnly={!residenceEdit}

          />
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup>
          <Label for="rules">Rules</Label>
          <ReactQuill
            value={values.rules}
            onChange={(value) => handleEditorChange(value, "rules")}
            placeholder="Enter Rules"
            formats={formats}
            modules={modules}
            className="react-quill-container" 
            readOnly={!residenceEdit}

          />
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup>
          <Label for="notes">Notes</Label>
          <ReactQuill
            value={values.notes}
            onChange={(value) => handleEditorChange(value, "notes")}
            placeholder="Enter Notes"
            formats={formats}
            modules={modules}
          />
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup>
          <Label for="payment_terms">Payment Terms</Label>
          <ReactQuill
            value={values.payment_terms}
            onChange={(value) => handleEditorChange(value, "payment_terms")}
            placeholder="Enter Payment Terms"
            formats={formats}
            modules={modules}
            readOnly={!residenceEdit}

          />
        </FormGroup>
      </Col>
      {/* <Col md={12}>
        <FormGroup>
          <Label for="landlord_conditions">Landlord Conditions</Label>
          <ReactQuill
            value={values.landlord_conditions}
            onChange={(value) => handleEditorChange(value, "landlord_conditions")}
            placeholder="Enter Landlord Conditions"
            formats={formats}
            modules={modules}
          />
        </FormGroup>
      </Col> */}
 
   
    </Row>
   
     <Row style={{marginBottom:"25px"}}>
  <Col md={6}>
    <Button color="secondary" onClick={() => setActiveTab("3")}>Back</Button>
  </Col>
  <Col md={6} className="d-flex justify-content-end">
{   !id  &&<Button type="button" color="primary" onClick={() => onChangeHandlerFn() } style={{marginBottom:"50px"}}>
Submit
     </Button>}
  </Col>
</Row>
</>

  );
};

EditerComponent.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onChangeHandlerFn: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,


};

export default EditerComponent;
