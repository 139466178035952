import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card } from "reactstrap";
import classnames from "classnames";
import Slots from "components/slots/Slots"
import { useNavigate, useParams } from "react-router-dom";
import { get, post } from 'helpers/api_helper';
import Swal from 'sweetalert2';
import authHeader from 'helpers/jwt-token-access/auth-token-header';
import moment from 'moment';

const ManageSlots = () => {
  const [activeTab, setActiveTab] = useState('1');
  const currentYear = moment().year();


  const [formData, setFormData] = useState({
    slot: ` 1 June ${currentYear} -  31 May ${currentYear+1}`, 
 

  });
  
  const { id } = useParams();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const navigate = useNavigate()
  const onInputChange = (updatedValues) => {
 
    setFormData((prevValues) => ({
      ...prevValues,
      slot: updatedValues.slot 
    }));
  };
  
  
  
  const fetchSlotById = async () => {
    try {
      const token = authHeader();
      const config = { headers: { Authorization: token } };
      const res = await get(`/slots/${id}`, config);

      if (res?.data) {
        setFormData({
            slot: res.data.slot ,
          description: res.data.description ,
    
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = async () => {
    const { slot, description,  } = formData;

    const payload = {
        slot,
      description,
     
    };

    const token = authHeader();
    const config = { headers: {
       Authorization: token 
    
    } };


    try {

       const url = id ? `/slots/${id}` : "/slots";
      const res = await post(url, payload, config);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: res?.message || 'Slots created successfully',
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate("/settings/slots")
        // setFormData({ role_name: "", description: "", selectedPermissions: {} });
      });
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error creating role',
        text: error.response?.data?.message || 'An unexpected error occurred',
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchSlotById();
    }
  }, [id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs maintitle="Home" title="Slots" breadcrumbItem={"Manage"} link="settings/slots"    />

          <Row>
            <Col className="col-12">
              <Card style={{ padding: "20px" }}>
                <Row className="justify-content-center">
                  <Col>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '1' })}
                          onClick={() => toggle('1')}
                        >
                          SLOTS
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>

                <TabContent activeTab={activeTab} className="mt-4">
                  <TabPane tabId="1">
                    <Slots
                      values={formData}
                      onInputChange={onInputChange}
                      onSubmit={handleSubmit}
                    />
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageSlots;
