import React, { useState, useEffect } from "react";
import { Input, Label, Row, Col, Button, FormGroup, FormFeedback } from "reactstrap";
import { get } from "helpers/api_helper";
import PropTypes from "prop-types";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SlotsRooms = ({ values, onInputChange, setActiveTab }) => {
  const [rows, setRows] = useState([]); // Slot rows state
  const [allSlot, setAllSlot] = useState([]); // All available slots from API

  const [selectedSlots, setSelectedSlots] = useState([]); // To track selected slots
  const [errors, setErrors] = useState({}); // Error state for validation

  // Fetch available slots from API
  const getAllSlot = async () => {
    const token = authHeader();
    const config = {
      headers: {
        Authorization: token,
      },
    };

    try {
      const res = await get(`/slots`, config);
      setAllSlot(res.data);
    } catch (error) {
      console.log("An error occurred", error);
    }
  };

  // Handle adding a new slot row
  const handleAddRow = () => {
    const newRow = { slot: "", status: "" };
    setRows([...rows, newRow]);
  };

  // Handle row change for both slot and status
  const handleRowChange = (idx, field, value) => {
    // Update the rows with the changed field and value
    const updatedRows = rows?.map((row, i) => {
      if (i === idx) {
        // If the slot is being updated, set booking_status to "Available"
        if (field === "slot" && value) {
          return { ...row, [field]: value, status: "Available" }; // Update booking_status
        }
        return { ...row, [field]: value }; // Otherwise, just update the field
      }
      return row;
    });
  
    // Handle slot-specific logic
    if (field === "slot") {
      const previousSlot = rows[idx].slot;
      const updatedSelectedSlots = previousSlot
        ? selectedSlots.filter((slot) => slot !== previousSlot)
        : selectedSlots;
  
      if (value) {
        setSelectedSlots([...updatedSelectedSlots, value]);
      } else {
        setSelectedSlots(updatedSelectedSlots);
      }
    }
  
    // Update the rows in state
    setRows(updatedRows);
  
    // Update any error state if necessary
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${field}_${idx}`]: value ? "" : prevErrors[`${field}_${idx}`],
    }));
  
    // Pass the updated rows to onInputChange
    const stringifiedRows = JSON.stringify(updatedRows);
    onInputChange({ target: { name: "slot", value: stringifiedRows } });
 
  };



  // Handle removing a row
  const handleRemoveRow = (idx) => {
    const updatedRows = rows?.filter((_, index) => index !== idx);
    const removedSlot = rows[idx].slot;

    // Remove the slot from selected slots if it was selected
    if (removedSlot) {
      const updatedSelectedSlots = selectedSlots.filter((slot) => slot !== removedSlot);
      setSelectedSlots(updatedSelectedSlots);
    }

    setRows(updatedRows);
    const stringifiedRows = JSON.stringify(updatedRows);
    onInputChange({ target: { name: "slot", value: stringifiedRows } });
  };
  const handleEditorChange = (value, name) => {
    onInputChange({ target: { name, value } });
  };
  // Validate form inputs before proceeding to the next tab
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate rows (slot and status)
    rows?.forEach((row, index) => {
      if (!row.slot) {
        errors[`slot${index}`] = "Slot is required.";
        isValid = false;
      }
      // if (!row.status) {
      //   errors[`status${index}`] = "Status is required.";
      //   isValid = false;
      // }
    });

    setErrors(errors);
    return isValid;
  };

  // Handle the Next button click
  const handleNext = () => {
    if (validateForm()) {
      setActiveTab("4"); // Move to the next tab (adjust index based on your tabs)
    }
  };

  // Effect to fetch slots and initialize rows based on props
  useEffect(() => {
    getAllSlot();
  
    // Parse slot JSON and set it to state
    const parseSlots = (slotsTaken) => {
      if (Array.isArray(slotsTaken)) {
        return slotsTaken;
      }
      try {
       

        // Parse if it's a valid JSON string, otherwise use default slot structure
        return slotsTaken ? JSON.parse(slotsTaken) : [{ slot: "", status: "" }];
      } catch (error) {
        console.error("Error parsing slot:", error);
        return [{ slot: "", status: "" }];
      }
    };
  
    // If values.slot is present, parse and set initial rows

    const parsedSlots = parseSlots(values.slot);

    setRows(parsedSlots); // Set the parsed slots
  
  }, [values.slot, values.textAboutSlot]);
  
  const formats = [
    "font", "size",
    "bold", "italic", "underline", "strike",
    "color", "background",
    "script",
    "header", "blockquote", "code-block",
    "indent", "list",
    "direction", "align",
    "link", "image", "video", "formula",
  ];

  // Modules for the editor
  const modules = {
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      [{ 'header': '1'}, {'header': '2'}, { 'script': 'sub'}, { 'script': 'super' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
      ['blockquote', 'code-block'],
      [{ 'direction': 'rtl' }, { 'align': [] }],
      ['link', 'image', 'video', 'formula'],
      ['clean']
    ],
  };

  // Helper function to get available slots for a specific row
  const getAvailableSlots = (currentRowSlot) => {
    return allSlot.filter((slot) => {
      return !selectedSlots.includes(slot.id) || slot.id === currentRowSlot;
    });
  };

  return (
    <div>
      {rows?.map((row, idx) => (
        <div id={"nested" + idx} key={idx}>
          <Row className="mb-3">
            <Col md={5}>
              <FormGroup>
                <Label>Slot</Label>
                <Input
                  type="select"
                  name="slot"
                  onChange={(e) => handleRowChange(idx, "slot", e.target.value)}
                  value={row.slot || ""}
                  invalid={!!errors[`slot${idx}`]}
                >
                  <option value="">Select Slot</option>
                  {getAvailableSlots(row.slot).map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.slot}
                    </option>
                  ))}
                </Input>
                {errors[`slot${idx}`] && (
                  <FormFeedback style={{ color: "red" }}>{errors[`slot${idx}`]}</FormFeedback>
                )}
              </FormGroup>
            </Col>

            <Col md={5}>
              <FormGroup>
                <Label>Status</Label>
                <Input
                  type="select"
                  name="status"
                  onChange={(e) => handleRowChange(idx, "status", e.target.value)}
                  value={row.status || "Available"}
                  // invalid={!!errors[`status${idx}`]}
                >
                  {/* <option value="">Select availability</option> */}
                  <option value="Available">Available</option>
                  <option value="Unavailable">Unavailable</option>
                </Input>
                {/* {errors[`status${idx}`] && (
                  <FormFeedback style={{ color: "red" }}>{errors[`status${idx}`]}</FormFeedback>
                )} */}
              </FormGroup>
            </Col>

            <Col md={2} className="d-flex align-items-center">
              <Button color="danger" onClick={() => handleRemoveRow(idx)} className="mt-3">
                Remove
              </Button>
            </Col>
          </Row>
        </div>
      ))}

      <Button color="primary" onClick={handleAddRow} className="mt-3">
        Add another slot
      </Button>

      <Row className="mt-3">
        <Col md={12}>
          <FormGroup>
            <Label for="description">Description</Label>
            <ReactQuill  value={values?.slot_comment || ""}
              onChange={(value) => handleEditorChange(value, "slot_comment")}
              placeholder="Enter comments for slot"
              formats={formats}
              modules={modules}
              
              />
          </FormGroup>
        </Col>
      </Row>
      
      <Row>
        <Col md={6}>
          <Button color="secondary" onClick={() => setActiveTab("3")}>
            Back
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

SlotsRooms.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default SlotsRooms;
