import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Card,
} from "reactstrap";
import classnames from "classnames";
import General from "components/general/ResidenceGeneral";
import FeatureResidence from "components/featureApartments/FeatureResidence";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./StyleTable.scss";
import { get, post, put } from "helpers/api_helper";
import Swal from "sweetalert2";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import MediaResidence from "components/mediaApartments/ResidenceMedia";
import Rooms from "../rooms/Rooms";
import EditerComponent from "components/editor/ResidenceEditor";
import isEqual from 'lodash/isEqual'; // Ensure lodash is imported
// import Slots from "components/slotCommon/Slots";
import { toggleResidenceEdit } from "store/residence/actions";
import { isAdmin } from "helpers/validateToken";
import {  useSelector, useDispatch} from 'react-redux';
import { hasPermission } from "helpers/validateToken";
const ResidenceManage = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [formData, setFormData] = useState({});
  const [initialFormData, setInitialFormData] = useState({}); // State for initial form data
  const [forrommID, setForrommID] = useState(false);
  const [isPermission, setIsPermission] = useState(false)

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  let URLdata = location?.state?.data;
  const { id } = useParams();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const user = JSON.parse(localStorage.getItem("authUser")); // Retrieve the logged-in user's information
  const userId = user.user?.id;
  const residenceEdit = useSelector((state) => state.residence.isEditing);


  const hasValues = () => Object.keys(formData).length > 0;

  // const onChangeHandlerFn = (data) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [data?.name]: data?.value,
  //   }));
  // };

  const onInputChange = (evt) => {
    if(!isAdmin){
      setFormData((prevValues) => ({
        ...prevValues,
        owner_id: userId, // Default to current user's ID
      }));
  }
    setFormData((prevData) => ({
      ...prevData,
      [evt.target.name]: evt.target.value,
    }));
  };

  const onAddressChange = (evt) => {
    const { name, value } = evt.target;
    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address, // Preserve existing fields in address
        [name.split(".")[1]]: value, // Update only the relevant field (city or street)
      },
    }));
  };

  const submitHandler = () => {
    const token = authHeader();
    const config = {
      headers: {
        Authorization: token,
      },
    };
    const requiredFields = ["name",
      // Only include "owner_id" if isAdmin is true
      ...(isAdmin ? ["owner_id"] : []),
    "neighborhood",
    "address.city",
    "address.street",
    "zipcode",
    "price",
    "deposit",
   
    "residence_type",
    "active",
      "features",
      "interior_exterior",
      "kitchen",
      "rooms",
      "size",
    
      "bathrooms",
      "bed_room"
   
   
  
  ]; // Add your required fields
    const isEmptyField = requiredFields.some((field) => {
      const value = field.split(".").reduce((o, i) => o[i], formData);
      return !value || (typeof value === 'string' && value.trim() === "");
    });

    if ( !id &&isEmptyField) {
      Swal.fire({
        icon: "warning",
        title: "Please fill in all required fields!",
        showConfirmButton: true,
      });
      return; // Stop submission if fields are empty
    }
    // Check if `id` is present to determine the correct URL and method
    const url = id ? `/residences/${id}` : "/residences";
    const method = id ? put : post; // Use `put` for updating, `post` for creating

    // Check if any changes were made
    const isChanged = !isEqual(initialFormData, formData);

    if (id && !isChanged) {
      Swal.fire({
        icon: "info",
        title: "No changes detected!",
        text: "Please make some changes before submitting.",
        showConfirmButton: true,
      });
      return; // Stop submission if no changes are detected
    }

    // Proceed with the API call
    method(url, formData, config)
      .then((res) => {
        localStorage.setItem(
          "residenceId",
          JSON.stringify(res.data.residence_id)
        );
        setForrommID(true);
        toggle("5");
    dispatch(toggleResidenceEdit(false))


        Swal.fire({
          position: "center",
          icon: "success",
          title:
            res?.message ||
            (id
              ? "Residence updated successfully"
              : "Residence created successfully with media"),
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          if (id) {
            navigate("/residence");
          } else {
            setActiveTab("5");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: id ? "Error updating Residence" : "Error creating Residence",
          text:
            error.message || "An error occurred while processing the Residence",
          showConfirmButton: true,
        });
      });
  };

  const fetchRedenceById = async () => {
    try {
      const token = authHeader(); // Fetch the authorization token
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const res = await get(`/residences/${id}`, config);
      setFormData(res?.data);
      setInitialFormData(res?.data); // Save initial data for comparison
    } catch (error) {
      console.log("error", error);
    }
  };

  const alertWhenNotResidence = () => {
    Swal.fire({
      position: "center",
      icon: "info",
      title: "Please Create Residence First!",
      timer: 2000,
    });
  };
  useEffect(() => {
    // Get permissions and user role from localStorage
    const obj = JSON.parse(localStorage.getItem("permission"))
    const permissions = JSON.parse(obj?.permission)


    // Check if the user has the permission to access 'residence'
    const hasPerm = hasPermission(
      permissions,
      "Residence",
      ["Read-only", "Read-write"],
      isAdmin
    )

    // If the user is Admin, they should have full access
    if (isAdmin) {
      setIsPermission(true) // Admin has access to everything
    } else if (!hasPerm) {
      navigate("/pages-404") // Redirect if no permission
      setIsPermission(false) // Set permission state to false
    } else {
      // Handle other permissions like 'Read-only' and 'Read-write'
      const role = permissions["Residence"]
      if (role === "Read-only") {
        setIsPermission(false) // Limited access
      } else if (role === "Read-write") {
        setIsPermission(true) // Full access
      } else {
        setIsPermission(false) // Default case
      }
    }
  }, [])
  useEffect(() => {
    if (id) {
      fetchRedenceById();
    }

    const params = new URLSearchParams(location.search);
    const selectValue = params.get("data");

    if (selectValue === "rooms" || URLdata === "rooms") {
      setActiveTab("5");
    }
  }, []);
  const  editResidence = ()=>{
    dispatch(toggleResidenceEdit(true))
    if(id && residenceEdit){
      submitHandler()
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Residence"
            breadcrumbItem={"Manage"}
            link="residence"
          />

          <Row>
            <Col className="col-12">
              <Col
                className="col-12 d-flex justify-content-end mt-3 me-3"
                style={{ paddingRight: "20px" }}
              ></Col>
              <Card style={{ padding: "20px" }}>
         {  id &&   activeTab !== "5"  && <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                >
               
             {   isPermission &&      <Button
                        color="primary"
                        onClick={editResidence}
                        // onClick={() => navigate(`/apartments/add`)}
                      >
                          { residenceEdit == false? " Edit Residence":"Update Residence"}
                      </Button>}
                
                </Col>}
                <Row className="justify-content-center">
                  <Col>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => toggle("1")}
                        >
                          GENERAL
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => toggle("2")}
                        >
                          FEATURES
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => toggle("3")}
                        >
                          MEDIA
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "4" })}
                          onClick={() => toggle("4")}
                        >
                          SLOTS
                        </NavLink>
                      </NavItem> */}
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "4" })}
                          onClick={() => toggle("4")}
                        >
                          OTHERS
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "5" })}
                          onClick={() => {
                            if (forrommID === false && !id) {
                              if (hasValues()) {
                                submitHandler(); // Only call this if formData has values
                              } else {
                                alertWhenNotResidence();
                              }
                            } else {
                              toggle("5");
                            }
                          }}
                        >
                          ROOMS
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>

                <TabContent activeTab={activeTab} className="mt-4">
                  <TabPane tabId="1">
                    <General
                      values={formData}
                      onInputChange={onInputChange}
                      onAddressChange={onAddressChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <FeatureResidence
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <MediaResidence
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  {/* <TabPane tabId="4">
                    <Slots
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane> */}
                  <TabPane tabId="4">
                    <EditerComponent
                      values={formData}
                      onInputChange={onInputChange}
                      onChangeHandlerFn={() =>
                        forrommID === false
                          ? submitHandler() // Only call this if formData has values
                          : toggle("4")
                      }
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    {activeTab === "5" && <Rooms values={formData} />}
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResidenceManage;
